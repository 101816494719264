import {GetApi, PostApi} from '../request'
import cogoToast from 'cogo-toast';

const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  

  // get transactions
  export const getTransactions = (status) => {
    return async (dispatch, getState) => {
        dispatch({ type : 'Loader'})
      try {
        const res = await GetApi(`transaction/user?status=${status}`, getToken(),);
        if (res.status === 200) {
          dispatch({ type: "Transaction", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Transaction_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
  };

  // Search transactions by amount, status
export const searchTransactions = (val) =>{
  return dispatch =>{
      dispatch({type: 'searchTransactions', data: val})
  }
}


// get all transactions with filter
export const getFilteredTransactions = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`transaction/user?status=${status}&from_date=${from_date}&to_date=${to_date}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Transaction", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Transaction_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};



// make a withdrawal request
export const WithdrawFunds = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "withdrawer/request/new",
        { 
          amount: val.amount,
          accountName: val.accountName,
          accountNumber: val.accountNumber,
          bankName: val.bankName,
          pin: val.pin,
          bankCode: val.bankCode,
          narration: val.narration
         },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "WithdrawSuccess" });
        cogoToast.success("Request Successful, Your payment is on the way!");
      }
      if (res.status === 400) {
        var msg = res.data.message;
        dispatch({ type: "Withdraw_Error", err: res.data });
        cogoToast.error(`${msg}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// clear withdrawal success
export const clearWithdrawal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearWithdrawal" });
  };
};




