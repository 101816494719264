const initState = {
    isAuthenticated: false,
    token: "",
    profile: {},
    role: "",
    accountDetails: [],
    profilePic: "",
    isSetPin: false,
    signup_success: false,
    signup_email: "",
    link_status: false,
    verified_msg: "",
    verified_status: false,
    resetcode: false,
    details: {},
    pinsuccess: false,
    forgetpinsuccess: false,
    validtoken: false,
    token_value: "",
    pinresetsuccess: false,
    pinload: false,
    banks: []
}
  
  const authReducer = (state = initState, action) => {
    switch (action.type) {
      case "LOGIN_SUCCESS":
        localStorage.setItem("token", action.data.token);
        return {
          ...state,
          isAuthenticated: true,
          token: action.data.token,
          profile: action.data.profile,
          role: action.data.profile.role,
          accountDetails: action.data.profile.accountDetails,
          profilePic: action.data.profile.profilePic,
          isSetPin: action.data.profile.isSetPin,
        };  
      case "SIGNUP_SUCCESS":
        return {
          ...state,
          signup_success: true,
          signup_email: action.data,
      }; 
      case "clearSignUpSuccess":
        return {
          ...state,
          signup_success: false,
       };  
    case "LINK_STATUS_ENABLED":
      return {
        ...state,
        link_status: true,
      };
    case "LINK_STATUS_DISABLED":
      return {
        ...state,
        link_status: false,
      };
      case "VerifiedUser":
        return {
          ...state,
          verified_msg: action.data,
          verified_status: true,
        };
      case "VerifiedUserError":
        return {
          ...state,
          verified_msg: action.data,
          verified_status: false,
        };
      case "VALID_RESETCODE":
        return {
          ...state,
          resetcode: false,
        };
      case "INVALID_RESETCODE":
        return {
          ...state,
          resetcode: true,
        };
      case "AccountCreateSuccessful":
          {
            return {
              ...state,
              accountDetails: action.data
            }
          }
       case "filterDetails":
            let details = state.accountDetails.find((pro) => pro.id === action.id);
          return {
            ...state,
            details: details,
          };
        case "AccountUpdatedSuccessful":
          return {
            ...state,
            accountDetails: action.data
          }
      case "AccountDeletedSuccessful":
          return{
            ...state,
            accountDetails: action.data
          }
      case "startpinLoad":
        return {
          ...state,
          pinload: true
        }
      case "stoppinLoad":
        return {
          ...state,
          pinload: false
        }
      case "PinCreateSuccess":
        return {
          ...state,
          isSetPin: true
        }
      case "PinSuccess":
        return {
          ...state,
          pinsuccess: true
        }
      case "PinFail":
        return {
          ...state,
          pinsuccess: false
        }
      case "ClearPinSuccess":
        return {
          ...state,
          pinsuccess: false
        }
     case "ForgetPinSuccess":
          return {
            ...state,
            forgetpinsuccess: true
          }
      case "ForgetPinFail":
          return {
            ...state,
            forgetpinsuccess: false
          }
      case "clearForgetPinSuccess":
        return {
          ...state,
          forgetpinsuccess: false
        }
      case "VALID_TOKEN":
          return {
            ...state,
            validtoken: true,
            token_value: action.data
          }
      case "INVALID_TOKEN":
          return {
            ...state,
            validtoken: false
          };
      case "clearValidToken":
          return {
            ...state,
            validtoken: false,
          }
      case "PinResetSuccess":
          return {
            ...state,
            pinresetsuccess: true
          }
        case "PinResetFail":
          return {
            ...state,
            pinresetsuccess: false
          }
        case "clearResetPinSuccess":
          return {
            ...state,
            pinresetsuccess: false,
            token_value: ""
          }
        case "Banks":
            return {
              ...state,
              banks: action.data
        }
      case "logout":
        return {
          ...state,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  