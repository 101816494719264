import React,{useRef, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './security.module.css'
import change_icon from '../../../assets/icons/change_pin.svg'
import reset_icon from '../../../assets/icons/reset_pin.svg'
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import { SetPinValidator } from "../../../validationSchema/validator";
import { connect } from 'react-redux';
import { clearResetPinSuccess, SetNewPin } from '../../../store/actions/auth';


const SetNewPinPage = ({setPin, pinresetsuccess, clearPin}) => {

    const ref = useRef();
    const navigate = useNavigate()

    const handleSubmit = async (values) => {
        console.log(values);
        await setPin(values)
    };

    useEffect(() => {
        if (pinresetsuccess) {
          // reset the form
          setTimeout(() => {
            navigate('/dashboard')
            clearPin();
          }, 3000);
        }
      }, [pinresetsuccess, clearPin, navigate]);
    

    return ( 
        <>
        <UserSidebar>
             <div>
                <p className="titlehead">Security</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className='mt-5 mb-5'>

                    <h6 className={styles.managepin_title}>Manage Pin</h6>

                    <div className="row mt-4">
                        <div className="col-lg-4">

                            <div className={styles.change_pin_div}>
                                <div className="text-center">
                                    <img
                                    src={change_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                    Change your pin
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/change-pin"
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Change Pin
                                    </Link>
                                </div>
                            </div>

                            <div className={`mt-4 ${styles.change_pin_div}`}>
                                <div className="text-center">
                                    <img
                                    src={reset_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                   Forgot Your Pin?
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/reset-pin"
                                    className={`btn ${styles.btn_change_active}`}
                                    >
                                    Reset Pin
                                    </Link>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-8 mt-lg-0 mt-4'>

                            <div className={styles.change_pin_form}>

                                <h6 className={styles.managepin_title}>Set New Pin</h6>
                                
                                <div className="mt-4">
                                    {/* form submission */}
                                    <Formik
                                    onSubmit={(values, { setSubmitting }) =>
                                        handleSubmit(values, setSubmitting)
                                    }
                                    validationSchema={SetPinValidator}
                                    initialValues={{
                                        pin: "",
                                        confirm_pin: "",
                                    }}
                                    >
                                    {({
                                        handleChange,
                                        isSubmitting,
                                        handleSubmit,
                                        handleBlur,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                        <Form ref={ref} onSubmit={handleSubmit}>
                                       

                                        {/* new password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="pin">Pin</label>
                                            <input
                                                type="password"
                                                className={`${styles.input_style}`}
                                                name="pin"
                                                placeholder="Enter a New Pin"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="pin"
                                                value={values.pin}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.pin && errors.pin}
                                            </small>
                                            </div>
                                        </div>

                                        {/* confirm password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="confirm_pin">
                                               Confirm Pin
                                            </label>
                                            <input
                                                type="password"
                                                className={`${styles.input_style}`}
                                                name="confirm_pin"
                                                placeholder="Confirm Pin"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="confirm_pin"
                                                value={values.confirm_pin}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.confirm_pin && errors.confirm_pin}
                                            </small>
                                            </div>
                                        </div>

                                        <div className="text-center mt-4">
                                            <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={`btn ${styles.btn_submit}`}
                                            >
                                            Set Pin
                                            </button>
                                        </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      pinresetsuccess: state.auth.pinresetsuccess,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setPin: (values) => dispatch(SetNewPin(values)),
      clearPin: () => dispatch(clearResetPinSuccess()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(SetNewPinPage);