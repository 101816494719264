import React from 'react';
import styles from './landing.module.css'
import client from '../../assets/images/Group.png'
import user1 from '../../assets/images/user-one.png'
import user2 from '../../assets/images/user-two.png'
import user3 from '../../assets/images/user-three.png'
import user4 from '../../assets/images/user-four.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import circle from '../../assets/icons/circle.svg'



const TestimonialSection = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 800,
        // autoplay: true,
        // autoplaySpeed: 5000,
        // cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return ( 
        <>
        <div className={styles.testimonial_div}>
            <div className='contain'>

                <div className='text-center'>
                    <h3 className={styles.testimonial_title}>What our happy client say</h3>
                    <p className={styles.testimonial_subtitle}>Several selected clients, who already believe in our service.</p>
                </div>

                <div className='row mt-5'>
                    <div className='col-lg-8'>

                    <Slider {...settings}>
                    
                        <div className={styles.testimonial_sect}>

                            <div className={styles.testimonial_img_div}>
                                <img src={user2} className="img-fluid"  alt="user" />

                                <div className={styles.test_circle}>
                                    <img src={circle} alt="circle" width="100" height="100" />
                                </div>
                            </div>

                            <div>
                                <h6 className={`mt-3 mt-lg-0 ${styles.name}`}>Esan Tolulope</h6>
                                <p className={`mt-2 mt-lg-3 ${styles.content}`}>
                            Being a victim of some other exchanges, I was scared to trade again until I met Ikinghub. I don’t fret or worry because my assets and cash are in good hands
                                </p>
                            </div>
                        </div>
                        
                        <div className={styles.testimonial_sect}>
                            <div className={styles.testimonial_img_div}>
                                <img src={user3} className="img-fluid"  alt="user" />

                                <div className={styles.test_circle}>
                                    <img src={circle} alt="circle" width="100" height="100" />
                                </div>
                            </div>

                            <div>
                                <h6 className={`mt-3 mt-lg-0 ${styles.name}`}>Mr Bayo</h6>
                                <p className={`mt-2 mt-lg-3 ${styles.content}`}>
                                My speed plug! It is nice knowing that anytime I need quick cash for my digital assets, they are always ready to come through.
                                </p>
                            </div>
                        </div>

                        <div className={styles.testimonial_sect}>

                            <div className={styles.testimonial_img_div}>
                                <img src={user4} className="img-fluid"  alt="user" />

                                <div className={styles.test_circle}>
                                    <img src={circle} alt="circle" width="100" height="100" />
                                </div>
                                
                            </div>

                            <div>
                                <h6 className={`mt-3 mt-lg-0 ${styles.name}`}>Miss Magdalene</h6>
                                <p className={`mt-2 mt-lg-3 ${styles.content}`}>
                                I have never encountered any issues while trading with them, quick to reply and quick payment. Iking na Baba. I recommend them to all my friends and partners.
                                </p>
                            </div>
                        </div>


                        <div className={styles.testimonial_sect}>

                            <div className={styles.testimonial_img_div}>
                                <img src={user1} className="img-fluid"  alt="user" />

                                <div className={styles.test_circle}>
                                    <img src={circle} alt="circle" width="100" height="100" />
                                </div>
                                
                            </div>

                            <div>
                                <h6 className={`mt-3 mt-lg-0 ${styles.name}`}>Mr Michael</h6>
                                <p className={`mt-2 mt-lg-3 ${styles.content}`}>
                                The experience with Iking is always superb, even if you do not know all about to cryptocurrency, the agents are always willing to take time and walk you through the basics
                                </p>
                            </div>
                        </div>

                     </Slider>


                    </div>

                    <div className='col-lg-4 d-none d-md-block'>

                        <div>
                            <img src={client} alt="client" className='img-fluid' />
                        </div>

                    </div>

                </div>

            </div>
        </div>
        </>
     );
}
 
export default TestimonialSection;