import React,{useState, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './account.module.css'
import DataTable from "react-data-table-component";
import Edit from "../../../assets/icons/edit.svg";
import Trash from "../../../assets/icons/trash.svg";
import { Form, Formik } from "formik";
import { bankAccountValidator } from "../../../validationSchema/validator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from 'react-redux';
import { addAccountDetails, deleteAccountDetails, filterDetails, getBankAccounts, updateAccountDetails } from '../../../store/actions/auth';
import Modal from "../../../components/Modals/FilterModal";
import { useFormik } from "formik";

const UsersAccountDetailsPage = ({accountDetails, addAccount, deleteAcct, handleFilter, details, updateAcct, getBanks, banks}) => {


    // const data = [
    //     {
    //         id: 1,
    //         accountName: 'Jaye Akinfenwa',
    //         bankName: 'First Bank',
    //         accountNumber: '0146464663',
    //     },
    //     {
    //         id: 2,
    //         accountName: 'Jaye Akinfenwa',
    //         bankName: 'First Bank',
    //         accountNumber: '0146464662',
    //     },
    // ]

    useEffect(() => {
      getBanks();
    }, [getBanks]);

    const [showModal, setShowModal] = useState(false);

    const {
      values,
      errors,
      touched,
      handleBlur,
      handleSubmit,
      handleChange,
      isSubmitting,
    } = useFormik({
      initialValues: {
        bankName:  banks.length > 0 && details.hasOwnProperty('bankCode')
        ? banks.find((pro) => pro.code === details.bankCode).id
        : "",
        accountNumber:  banks.length > 0  && details.hasOwnProperty('accountNumber') ? details.accountNumber: "", 
        accountName: banks.length > 0  && details.hasOwnProperty('accountName') ? details.accountName : "",
      },
      enableReinitialize: true,
      validationSchema: bankAccountValidator,
      onSubmit(values, { setSubmitting }) {
        handSubmit(values, setSubmitting);
      },
    });

    const columns = [
        {
            name: "Account Name",
            cell: (row) => (
              <span className={styles.recent_table_row}>
                {" "}
                {row.accountName}
              </span>
            ),
          }, 
        {
            name: "Bank Name",
            cell: (row) => (
              <span className={styles.recent_table_row}>
                {" "}
                {row.bankName}
              </span>
            ),
          },
          {
            name: "Account Number",
            cell: (row) => (
                <span className={styles.recent_table_row}>
                  {" "}
                  {row.accountNumber}
                </span>
              ),
          }, 
        {
          name: "Actions",
          cell: (row) => (
            <div>
              <img
                src={Edit}
                alt="edit"
                width="20"
                height="20"
                style={{ cursor: "pointer" }}
                onClick={() => OpenEditModal(row.id)}
              />
              <img
                src={Trash}
                alt="check"
                className="ml-2"
                width="20"
                height="20"
                style={{ cursor: "pointer" }}
                onClick={() => OpenDeleteModal(row.id)}
              />
            </div>
          ),
        },
      ];

      
  const OpenEditModal = (id) => {
    handleFilter(id);
    setShowModal(true);
  };

  const OpenDeleteModal = (id) => {
    confirmAlert({
        title: "Confirm to submit",
        message: `You are about to remove this account details, Do you wish to continue?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => confirmDelete(id),
          },
          {
            label: "No",
          },
        ],
      });
  };

  const confirmDelete = (id) => {
    deleteAcct(id)
  };

  const handleAdd = async (values) => {
    //  //  get bank Name
    var bankId = values.bankName;
    var bankName = banks.find((pro) => pro.id === parseInt(bankId)).name;

    // get bank code
    var bankCode = banks.find((pro) => pro.id === parseInt(bankId)).code;
    const creds  = {
      ...values,
      bankName: bankName,
      bankCode: bankCode,
    }
    await addAccount(creds)
};

const handSubmit = async (values, setSubmitting) => {
  setSubmitting(true)
  console.log(values)
    //  //  get bank Name
  var bankId = values.bankName;
  var bankName = banks.find((pro) => pro.id === parseInt(bankId)).name;

  // get bank code
  var bankCode = banks.find((pro) => pro.id === parseInt(bankId)).code;
  const creds  = {
    ...values,
    bankName: bankName,
    bankCode: bankCode,
  }
  await updateAcct(creds)

  setTimeout(()=>{
    setSubmitting(false)
    setShowModal(false)
  }, 2000)
}

      
    return ( 
        <>
        <UserSidebar>
         <div>
            <p className="titlehead">Account Details</p>
          </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">
               <Modal
                    title="Edit Bank Account Details"
                    show={showModal}
                    onClose={() => setShowModal(false)}
                  >
                    <div>
                  
                        <form onSubmit={handleSubmit}>
                          <div className="mt-4">
                            <div className={`mb-3 ${styles.input_container}`}>
                              <label htmlFor="bankName">Bank</label>
                              <select
                                  name="bankName"
                                  values={values.bankName}
                                  onChange={(e) => {
                                      handleChange(e);
                                  }}
                                  defaultValue=""
                                  onBlur={handleBlur}
                                  className={`${styles.input_style}`}
                                  id="bankName"
                                  >
                                  <option
                                    value={
                                      banks.length > 0  && details.hasOwnProperty('bankCode') ? banks.find(
                                        (pro) => pro.code === details.bankCode
                                      ).id : ''
                                    }
                                  >
                                  {details.bankName}
                                </option>
                                    {banks
                                .filter((val) => val.code !== details.bankCode)
                                .map((opt, index) => {
                                  return (
                                    <option key={index} value={opt.id}>
                                      {opt.name}
                                    </option>
                                  );
                                })}
                                    
                              </select>
                              <small style={{ color: "#dc3545" }}>
                                  {touched.bankName && errors.bankName}
                              </small>
                          </div>

                          {/* Account Number*/}
                          <div className={`mb-3 ${styles.input_container}`}>
                              <label htmlFor="accountNumber">Account Number</label>
                              <div style={{ position: "relative" }}>
                                  <input
                                      className={`${styles.input_style}`}
                                      type="text"
                                      placeholder="Enter your account number"
                                      id="accountNumber"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.accountNumber}
                                  />
                              </div>
                              <small style={{ color: "#dc3545" }}>
                                  {touched.accountNumber && errors.accountNumber}
                              </small>
                          </div>

                          <div className={`mb-3 ${styles.input_container}`}>
                              <label htmlFor="accountName">Account Name</label>
                              <div style={{ position: "relative" }}>
                                  <input
                                      className={`${styles.input_style}`}
                                      type="text"
                                      placeholder="Account Name"
                                      id="accountName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      
                                      value={values.accountName}
                                  />
                              </div>
                              <small style={{ color: "#dc3545" }}>
                                  {touched.accountName && errors.accountName}
                              </small>
                          </div>

                          <div className="mt-4 text-center">
                          <button
                              type="submit"
                              disabled={isSubmitting}
                              className={`btn ${styles.btn_add}`}
                          >
                              Save
                          </button>
                          </div>
                          </div>
                        </form>
                   
                  </div>
                  </Modal>

                <div className='row mt-5 mb-5'>
                    <div className='col-lg-8'>

                    <div className={styles.account_div}>
                        <DataTable
                        title="Accounts"
                        pagination
                        persistTableHead
                        progressPending={false}
                        columns={columns}
                        data={accountDetails}
                        />
                    </div>

                    </div>

                    <div className='col-lg-4 mt-md-0 mt-3'>

                        <div className={styles.add_account_div}>
                            
                            <h6 className={styles.add_title}>Add Account</h6>

                            <div>

                            <Formik
                            onSubmit={(values, { setSubmitting }) =>
                            handleAdd(values, setSubmitting)
                            }
                            validationSchema={bankAccountValidator}
                            initialValues={{ bankName: "",accountNumber: "", accountName: ""}}
                            >
                            {({
                            handleChange,
                            isSubmitting,
                            handleSubmit,
                            handleBlur,
                            values,
                            touched,
                            errors,
                            }) => (
                            <Form onSubmit={handleSubmit}>
                            {/* form section */}
                            <div className="mt-4">

                                <div className={`mb-3 ${styles.input_container}`}>
                                    <label htmlFor="bankName">Bank</label>
                                    <select
                                        name="bankName"
                                        values={values.bankName}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        defaultValue=""
                                        onBlur={handleBlur}
                                        className={`${styles.input_style}`}
                                        id="bankName"
                                        >
                                        <option value="" disabled>
                                                Select Bank
                                        </option>
                                        {banks.map((opt, index) => {
                                          return (
                                            <option key={index} value={opt.id}>
                                              {opt.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.bankName && errors.bankName}
                                    </small>
                                </div>

                                {/* Account Number*/}
                                <div className={`mb-3 ${styles.input_container}`}>
                                    <label htmlFor="accountNumber">Account Number</label>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            className={`${styles.input_style}`}
                                            type="text"
                                            placeholder="Enter your account number"
                                            id="accountNumber"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.accountNumber}
                                        />
                                    </div>
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.accountNumber && errors.accountNumber}
                                    </small>
                                </div>

                                <div className={`mb-3 ${styles.input_container}`}>
                                    <label htmlFor="accountName">Account Name</label>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            className={`${styles.input_style}`}
                                            type="text"
                                            placeholder="Account Name"
                                            id="accountName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.accountName}
                                        />
                                    </div>
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.accountName && errors.accountName}
                                    </small>
                                </div>

                                <div className="mt-4 text-center">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`btn ${styles.btn_add}`}
                                >
                                    Add Account
                                </button>
                                </div>
                            </div>
                            </Form>
                            )}
                            </Formik>

                            </div>




                        </div>
                    </div>

                </div>

            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) =>{
  return{
    accountDetails: state.auth.accountDetails,
    details: state.auth.details,
    banks: state.auth.banks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAccount: (val) => dispatch(addAccountDetails(val)),
    deleteAcct: (id) => dispatch(deleteAccountDetails(id)),
    handleFilter: (id) => dispatch(filterDetails(id)),
    updateAcct: (val) => dispatch(updateAccountDetails(val)),
    getBanks: () => dispatch(getBankAccounts()),
  };
};

 
export default connect(mapStateToProps, mapDispatchToProps)(UsersAccountDetailsPage);