import {GetApi, PostApi, PatchApi} from '../request'
import cogoToast from 'cogo-toast';
import axios from 'axios';
import { apiUrl } from '../config';

const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  


// get all giftcards trade
export const getGiftCardsTrade = (status) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/giftcard/trade/user?status=${status}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Trades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Trades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};


// get all crypto trades
export const getCryptoTrades = (status) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/coin/user?status=${status}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Trades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Trades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get all giftcards trade with filter
export const getFilteredGiftCardsTrade = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/giftcard/trade/user?status=${status}&from_date=${from_date}&to_date=${to_date}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Trades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Trades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get all crypto trade with filter
export const getFilteredCryptoTrade = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/coin/user?status=${status}&from_date=${from_date}&to_date=${to_date}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Trades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Trades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};


// Search trades by amount
export const searchTrade = (val) =>{
  return dispatch =>{
      dispatch({type: 'searchTrade', data: val})
  }
}

export const createCardTrade = (val) => {
  return async (dispatch, getState) => {
      dispatch({ type: 'TRADE_START' });
    try {
      const res = await PostApi("trade/giftcard/new", {
          cardAmount: val.cardAmount,
          amount: val.amount,
          categoryId: val.categoryId,
          subCategoryId: val.subCategoryId,
          imageUrl: val.imageUrl,
          comment: val.comment
      }, getToken(), "application/json")
      if (res.status === 201) {
        dispatch({ type: 'TRADE_SUCCESS' });
        cogoToast.success('Trade created successfully!')
      }
      if(res.status === 400){
        // invalid trade
        dispatch({ type: 'TRADE_ERROR' });
        cogoToast.error('Ooops!, looks like something went wrong with this trade. Please try with another card upload')
      }
    } catch (err) {
      console.log(err)
    }
  };
};


export const uploadImage = (file) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'STARTUPLOAD' });
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios({      
          method: "post",
          url: `${apiUrl}files/upload`,
          data: formData,
          headers: { 
            "Content-Type": "multipart/form-data",
            "Authorization": getToken()
          },
        
      })
      if (res.status === 201) {
        dispatch({ type: 'STOPUPLOAD' });
        dispatch({ type: 'ImageSuccess', data: res.data });
        cogoToast.success('Image uploaded successfully!')
      }
      if(res.status === 400){
        // invalid file
        dispatch({ type: 'STOPUPLOAD' });
        dispatch({ type: 'Image_ERROR' });
        cogoToast.error('Ooops!,an error occured while uploading trade')
      }
    } catch (err) {
      console.log(err)
      dispatch({ type: 'STOPUPLOAD' });
    }
  };
};



 // clear clearCardTradeSuccess
 export const clearCardTradeSuccess = () =>{
  return dispatch =>{
      dispatch({type: 'clearCardTradeSuccess'})
  }
}

// create a crypto trade
export const createCryptoTrade = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "trade/coin/new",
        { ...val },
        getToken(),
        "application/json"
      );
      if (res.status === 201) {
        dispatch({type: "CryptoTradeDisplay", data: {...val}})
        dispatch({ type: "CryptoTradeSuccess", data: res.data.message });
        cogoToast.success("Trade created successfully!");
      }
      if (res.status === 400) {
        dispatch({ type: "CryptoTrade_Error", err: res.data });
          cogoToast.error("Error while creating Trade!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// upload payment proof
export const UploadProof = (id, url) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'STARTUPDATE' });
    const values = {
      imageurlPaymentProof: url
    }
    try {
      const res = await PatchApi(
        `trade/coin/payment/proof/${id}`,
        { ...values },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: 'STOPUPDATE' });
        dispatch({ type: 'ProofUpdated' });
        cogoToast.success("Proof updated successfully!");
      }
      if (res.status === 400) {
          dispatch({ type: 'STOPUPDATE' });
          cogoToast.error("Error while updating Trade!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};




// clear crypto trade success
export const clearCryptoTradeSuccess = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearCryptoTradeSuccess" });
  };
};


// clear crypto trade result
export const clearCryptoTradeResult = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearCryptoResult" });
  };
};

// clear proof
export const clearProof = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearProof" });
  };
};






