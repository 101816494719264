import React from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './wallet.module.css'
import sent_circle from '../../../assets/icons/sent_circle.svg'
import get_circle from '../../../assets/icons/get_circle.svg'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "react-moment";

const TransactionDetails = ({transactions}) => {

    const {id} = useParams()

    const transaction = transactions.find((val) => val._id === id);


    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_badge
            break;
          case "Successful":
            result = styles.success_badge
            break;
          case "Failed":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };


    return (  
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Transaction Details</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.details_card}`}>

                <div className='row'>
                    <div className='col-lg-6 mb-4 mb-lg-0'>
                        <div className={styles.sent_card}>
                            <div className={styles.sent_sect}>
                                <div className='mr-4'>
                                    <img src={sent_circle} alt="circle" />
                                </div>
                                <div>
                                    <h6 className={styles.sent_title}>Wallet Balance</h6>
                                    <h5 className={styles.sent_subtitle}>NGN {transaction.finalBalance}</h5>
                                </div>
                            </div>       
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className={styles.sent_card}>
                            <div className={styles.sent_sect}>
                                <div className='mr-4'>
                                    <img src={get_circle} alt="circle" />
                                </div>
                                <div>
                                    <h6 className={styles.sent_title}>Transaction Amount</h6>
                                    <h5 className={styles.sent_subtitle}>NGN {transaction.amount}</h5>
                                </div>
                            </div>       
                        </div>
                    </div>

                </div>

                <div className='row mt-4'>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Transaction Id</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{transaction._id}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Date</h6>
                            <p className={`mt-1 ${styles.details_value}`}>
                                <Moment format="MMMM Do, YYYY">
                                {transaction.createdAt}
                                </Moment>
                                </p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Type</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{transaction.type}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Amount</h6>
                            <p className={`mt-1 ${styles.details_value}`}>NGN {transaction.amount}</p>
                        </div>
                    </div>

                    {/* <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Account Number</h6>
                            <p className={`mt-1 ${styles.details_value}`}>0148800246</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Bank</h6>
                            <p className={`mt-1 ${styles.details_value}`}>Guaranty Trust Bank</p>
                        </div>
                    </div> */}

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Narration</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{transaction.narration}</p>
                        </div>
                    </div>

        
                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Trade Status</h6>
                            <div className='d-flex'>
                                <p className={`mt-2 ${styles.details_value} ${getStatusColor(transaction.status)}`}>{transaction
                                .status}</p>
                            </div>
                            
                        </div>    
                    </div>

                </div> 
             </div>



            </div>
        </div>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        transactions: state.transaction.transactions,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);