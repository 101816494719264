import React from 'react'
import styles from './landing.module.css'
import Logo from '../../assets/icons/ikinghub-white.svg'
import { HashLink as Link } from "react-router-hash-link";
import facebook from '../../assets/icons/facebook.svg'
import instagram from '../../assets/icons/instagram.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'


const Footer = () => {
    return ( 
        <>
            <div className={styles.section_footer}>
                <div className='contain'>

                    <div className='row'>
                        <div className='col-lg-4'>
                            <div>
                              <img src={Logo} className="img-fluid" alt="Ikinghub Logo" />
                            </div>
                            <p className={`mt-3 ${styles.footer_info}`}>
                            Ikinghub was founded in 2018. What started as an impossible dream has become one of be Nigeria’s biggest financial innovations. We have been here for Four years, and we would always be here. We have conducted transactions in all regions of the country and our records speaks for our reputation.
                            </p>

                            <div className={styles.social_icons}>
                                <div className={`${styles.social_div} ${styles.social_facebook}`}>
                                    <a href="https://web.facebook.com/people/IkingHub-Xchange/100060850447956/" target='_blank' rel='noreferrer'>
                                        <img src={facebook} className="img-fluid" alt="facebook Logo" />
                                    </a>
                                </div>

                                <div className={`${styles.social_div} ${styles.social_whatsapp}`}>
                                    <a href="https://api.whatsapp.com/send?phone=+234 810 0993483&amp;text=Hello IkingHub, I want to trade with you" target='_blank' rel='noreferrer'>
                                        <img src={whatsapp} className="img-fluid" alt="Whatsapp Logo" />
                                    </a>
                                </div>

                                <div className={`${styles.social_div} ${styles.social_instagram}`}>
                                    <a href="https://www.instagram.com/ikinghub_xchange" target="_blank" rel='noreferrer'>
                                        <img src={instagram} className="img-fluid" alt="instagram Logo" />
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-2'>
                            <h6 className={`mt-5 mt-lg-4 ${styles.footer_link_title}`}>
                                What We Do
                            </h6>
                            <ul className={`mt-3 mt-lg-4 ${styles.footer_links}`}>
                                <li>
                                    <Link to="/">GiftCard to Naira</Link>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/">Crypto Trading</Link>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/">Instant Payments</Link>
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-2'>
                            <h6 className={`mt-3 mt-lg-4 ${styles.footer_link_title}`}>
                               Our Links
                            </h6>
                            <ul className={`mt-3 mt-lg-4 ${styles.footer_links}`}>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/#about">About</Link>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/#rate">Rate Calculator</Link>
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-2'>
                            <h6 className={`mt-3 mt-lg-4 ${styles.footer_link_title}`}>
                             Support
                            </h6>
                            <ul className={`mt-3 mt-lg-4 ${styles.footer_links}`}>
                                <li>
                                    <Link to="/#faq">FAQ</Link>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/privacy">Policy</Link>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/terms">Terms and Conditions</Link>
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-2'>
                            <h6 className={`mt-3 mt-lg-4 ${styles.footer_link_title}`}>
                             Contact
                            </h6>
                            <ul className={`mt-3 mt-lg-4 ${styles.footer_links}`}>
                                <li>
                                    <a 
                                    target="_blank"
                                    rel='noreferrer'
                                    href="https://api.whatsapp.com/send?phone=+234 810 0993483&amp;text=Hello IkingHub, I want to trade with you"
                                     >Whatsapp</a>
                                </li>
                                <li className='mt-2'>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className='text-center mt-3'>
                         <p className={`mb-0 ${styles.footer_copyright}`}>
                            Copyright &copy; {new Date().getFullYear()} IkingHub
                        </p>
                    </div>

                </div>
            </div>
        </>
     );
}
 
export default Footer;