import React, {useState} from 'react';
import styles from './landing.module.css'
import help_icon from '../../assets/icons/help-circle.svg'
import arrowDown from '../../assets/icons/arrow-down-circle.svg'
import arrowUp from '../../assets/icons/arrow-up-circle.svg'


const FAQSection = () => {

    const [val, setVal] = useState(null);

  const toggleCard = (id) => {
     setVal(id === val ? null : id)      
   };

   const FAQS = [
     {
       id: 1,
       title: "How long does a trade take?",
       content: () => (
         <>
           A trade takes an average of two minutes irrespective of the traffic or number of transactions. We have multiple agents on ground who are ready to aid you with the transaction. Your time is important to us and we know more time on your hands means more money.
         </>
       ),
     },
     {
      id: 2,
      title: "Do you accept USDT from all networks ?",
      content: () => (
        <>
          No, for now we accept USDT only from the BEP20 network , we are presently working on integrating TRC20,ERC20, SOL real soon.
        </>
      )
     },
     {
       id: 3,
       title: "Is ikinghub registered and legit ?",
       content: () => <>
       Ikinghub is registered as mandated by the necessary laws and regulations of the Federal Republic of Nigeria.</>
     },
     {
       id: 4,
       title: "Can I trade on the website?",
       content: () => (
         <>
         You can trade on the website. There are also links which automatically take you to our other mediums of contact. You have the option to either trade via our website or any other of our social media channels. You’re a king, it’s our job to treat you as such
         </>
       ),
     },
     {
       id: 5,
       title: "Inquiry and Complaints",
       content: () => <>
       Our systems and modes of transactions have been tested by experts and other users to ensure premium delivery. However, any complaints or inquiry can be directed to any of our social media channels and yu would be attended to immediately by our agents who are always happy to help. You could also fill the form on the website and you are still assured of a quick response</>,
     },   
     {
       id: 6,
       title: "How do I know if a transaction is completed?",
       content: () => (
         <>
         You are being notified via email the amount deposited into your account. Your smiles are what keep us going.
         </>
       ),
     },
   ];
  

    return ( 
        <>
        <div className={styles.faq_div} id="faq">

        <div className='contain'>
                <div className='row'>
                    <div className='col-lg-5'>

                        <div className={styles.faq_first}>
                            <div>
                               <img src={help_icon} className="img-fluid" alt="help" /> 
                            </div>
                            <h3 className={styles.faq_title}>Frequently asked questions.</h3>
                            <p className={`mt-2 mt-lg-4 ${styles.faq_subtitle}`}>We've got a quick response to most of your questions here!</p>
                        </div>

                    </div>
                    <div className='col-lg-7 mt-3 mt-lg-0'>

                        <div className={styles.faq_accordion}>

                        {FAQS.map(({ id, title, content }) => (
                                            
                                <div  key={id}
                                 onClick={() => toggleCard(id)}
                                 className={`mb-3 mb-md-3 ${styles.card}`}>
                                <div 
                                 className={
                                    val === id ? "p-3 p-md-4" : "collapsed p-3 p-md-4"
                                  }
                                style={{ cursor: "pointer" }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className={`mb-0 mr-2 ${styles.faq_question}`}>{title}</p>
                                        <img
                                        src={val === id ? arrowUp : arrowDown}
                                        style={{ height: "1.5rem" }}
                                        className="img-fluid"
                                        alt="arrow down"
                                        width="24"
                                        height="24"
                                        />
                                    </div>
                                </div>

                                <div className={
                                        val === id
                                        ? "collapse show animateshow"
                                        : "collapse animate"
                                    }>
                                    <div className='px-3 px-md-4 pb-3 pb-md-4'>
                                        <p className={`mb-0 ${styles.faq_answer}`}>{content()} </p>
                                    </div>

                                </div>

                                </div>
                           ))}

                        </div>

                    </div>
                </div>
            </div>

        </div>
        </>
     );
}
 
export default FAQSection;