import React from 'react'
import styles from './landing.module.css'
import ellipse from '../../assets/icons/Ellipse.svg'

const StepsPage = () => {

   

    return ( 
        <>
        <div className={styles.steps_div}>
            <div className='contain'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <div className={styles.steps_first}>
                             <div className='mb-2'>
                               <img src={ellipse} className="img-fluid" alt="ellipse" /> 
                            </div>
                            <h3 className={styles.steps_title}>We simplify trading crypto.</h3>
                            <p className={`mt-2 mt-lg-3 ${styles.steps_subtitle}`}>
                            For a small startup that began in a university community, we have become a national sensation. With a founder whose dreams never stop, we moved from processing thousands of naira monthly to currently trading millions of naira in weeks.</p>
                        </div>

                    </div>
                    <div className='col-lg-7 mt-lg-0 mt-3'>

                        <div className={styles.steps_card}>
                            <p className={styles.steps_content}>Create an account and sign in to your dashboard</p>
                        </div>

                        <div className={styles.steps_card}>
                            <p className={styles.steps_content}>Set up transaction pin to secure your transactions</p>
                        </div>

                        <div className={styles.steps_card}>
                            <p className={styles.steps_content}>Easily start a trade with your giftcards or coins</p>
                        </div>

                        <div className={styles.steps_card}>
                            <p className={styles.steps_content}>Once approved and confirmed, the equivalent amount is credited into your wallet </p>
                        </div>

                        <div className={styles.steps_card}>
                            <p className={styles.steps_content}>Withdraw your funds</p>
                        </div>

                    </div>

                </div>

            </div>

        </div>
        </>
     );
}
 
export default StepsPage;