import React,{useState} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './starttrade.module.css'
import { CopyToClipboard } from "react-copy-to-clipboard";
import cogoToast from "cogo-toast";
import copycontent from "../../../assets/icons/content-copy.svg";
import QRCode from "react-qr-code";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearCryptoTradeResult } from '../../../store/actions/trade';

const CryptoSuccessPage = ({trade_result, clearVal, trade_display}) => {

    const navigate = useNavigate()

const handleSubmit = async (values, setSubmitting) => {  
        clearVal(); 
        navigate("/start-trade/crypto");
    };

    const [walletAddr] = useState(
        trade_result && trade_result.walletAddress
          ? trade_result.walletAddress
          : "3B8z7QdsnfRv79G3ademoDZgssktSaK"
      );
    
      const handleCopy = () => {
        cogoToast.success("Copied to clipboard");
    };

    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Trade Crypto</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className='row mt-5 mb-5'>
                    <div className='col-lg-4 mt-4 mt-lg-0'>
                        <div className={styles.coin_div}>
                           
                        <div>
                            <form onSubmit={handleSubmit}>
                             
                                <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                <label htmlFor="amount">
                                    Coin Type
                                </label>
                                <input  
                                    disabled
                                    id="amount"
                                    className={`${styles.input_style}`}
                                    placeholder="BTC"
                                    type="text"
                                    value={
                                        trade_display && trade_display.coinType
                                          ? trade_display.coinType
                                          : "e.g BTC"
                                      }
                                />
                              
                                </div>

                                <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="usdamount">Coin Amount</label>
                                    <input
                                        disabled
                                        id="usdamount"
                                        className={`${styles.input_style}`}
                                        placeholder="0.00"
                                        type="text"
                                        value={
                                            trade_display && trade_display.amounttInCoin
                                              ? trade_display.amounttInCoin
                                              : "e.g 0.00"
                                          }
                                    />
                                   
                                </div>

                                <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="amount">You get (Naira)</label>
                                    <input            
                                        id="amount"
                                        className={`${styles.input_style}`}
                                        placeholder="NGN 0.00"
                                        disabled
                                        type="text"
                                        value={
                                            trade_display && trade_display.amountInLocalCurrency
                                              ? `NGN ${trade_display.amountInLocalCurrency}`
                                              : "NGN 0.00"
                                          }
                                       
                                    />
                                    </div>

                                <div className="text-center mt-4 mb-2">
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={`btn ${styles.btn_sell}`}
                                >
                                    Start Another Trade
                                </button>
                                </div>
                                <small>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Funds will be sent to your wallet after transaction is
                                    successfully completed by you and processed by us.
                                </small>
                            </form>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-8 order-first order-lg-last'>

                        <div className={styles.qr_result_div}>

                             <p className={styles.qr_title}>
                                You can send any amount of coins to the wallet below, the
                                system will credit you based on the amount received.
                                Please trade all USDT on the BEP20 network to avoid loss of funds!
                             </p>

                             <div>
                                <div className={`mt-4 ${styles.qr_input_container}`}>
                                    <div className={styles.copy_content}>
                                    <CopyToClipboard
                                        text={walletAddr}
                                        onCopy={() => handleCopy()}
                                    >
                                        <img
                                        src={copycontent}
                                        width="20"
                                        height="20"
                                        alt="copy"
                                        />
                                    </CopyToClipboard>
                                    </div>
                                    <input
                                    id="amount"
                                    className={`text-left text-lg-center ${styles.qr_input_style}`}
                                    placeholder="3B8z7QdsnfRv79G3a1GfmsUqDZgssktSaK"
                                    type="text"
                                    disabled
                                    value={walletAddr}
                                    />
                                </div>
                                </div>

                             <div className={`mt-4 ${styles.qr_title}`}>
                                 <p>you can also scan this QR code.</p>
                            </div>

                             {/* qr code div */}
                            <div className={`text-center ${styles.qr_code_div}`}>
                            {/* <img src={qrcode} className="img-fluid" alt="qrcode" /> */}
                            <QRCode
                                title="Wallet Address"
                                size="220"
                                value={walletAddr}
                            />
                            </div>


                            <div className="text-center mt-3">
                                <p className={styles.qr_title}>
                                    Your wallet will be credited instantly after 2 block
                                    confirmations.
                                </p>
                                </div>

                                <div className="text-center">
                                <small
                                    style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    lineHeight: "18px",
                                    display: "flex",
                                    }}
                                    className={styles.qr_title}
                                >
                                    <i
                                    className="mdi mdi-information-outline"
                                    style={{ color: "#FF8400", fontSize: 18 }}
                                    ></i>
                                    Kindly make payment to this wallet address within an hour
                                    else payment will be delayed and manually resolved by us.
                                </small>
                                </div>

                          
                            

                        </div>

                    </div>

                </div>



            </div>
        </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
      trade_display: state.trade.trade_display,
      trade_result: state.trade.trade_result,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      clearVal: () => dispatch(clearCryptoTradeResult()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(CryptoSuccessPage);