import { combineReducers } from "redux";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import notifyReducer from "./notifications";
import rateReducer from "./rate";
import tradeReducer from "./trade";
import transactionReducer from "./transactions";


const appReducer = combineReducers({
  auth: authReducer,
  rate: rateReducer,
  trade: tradeReducer,
  transaction: transactionReducer,
  dashboard: dashboardReducer,
  notification: notifyReducer
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
  