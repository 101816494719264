const initState = {
    WalletBalance: 0,
    transactionAnalytics: [],
    pendingTransactions: 0,
    allTimeDebit: 0,
    allTimeCredit: 0
  };
  
  const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
      case 'WalletBalance':
          return {
                ...state,
                WalletBalance: action.data,
            }
        case 'Analytics':
          return{
            ...state,
            pendingTransactions: action.data.pendingTransactions,
            transactionAnalytics: action.data.transactionAnalytics,
            allTimeCredit: action.data.allTimeCredit,
            allTimeDebit: action.data.allTimeDebit
          }
      default:
        return state;
    }
  };
  
  export default dashboardReducer;
  