import {GetApi} from '../request'



// get all rates category
export const getRateCategory = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("ratecalculator/giftcard/category/listing", "iking2022");
      if (res.status === 200) {
        dispatch({ type: "Category", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Category_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};


// get all rates subcategory
export const getRateSubCategory = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("ratecalculator/giftcard/subcategory/listing/"+val, "iking2022");
      if (res.status === 200) {
        dispatch({ type: "subCategory", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "subCategory_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};


export const getRateValue = (amount, id) =>{
    return dispatch =>{
        dispatch({type: 'RateCalculation', data: {
            amount,
            id
        }})
    }
}


export const getUserRateValue = (amount, id) =>{
  return dispatch =>{
      dispatch({type: 'UserRateCalculation', data: {
          amount,
          id
      }})
  }
}


// clear user trade amount to zero after successful trade
export const clearTradeAmount = () =>{
  return dispatch =>{
      dispatch({type: 'clearTradeAmount'})
  }
}



export const getTermsAndConditions = (id) =>{
  return dispatch =>{
      dispatch({type: 'Terms', data: id})
  }
}

// crypto  coin section

// Get all coins
export const getCoinsRate = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi("ratecalculator/coin/rates", "iking2022");
      if (res.status === 200) {
        dispatch({ type: "Coins", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "Coins_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// calculate coin rate based on coin selected and amount
export const getCoinRateValue = (amount, id,countryTag) => {
  return (dispatch) => {
    dispatch({
      type: "CoinRateCalculation",
      data: {
        amount,
        id
      },
    });
  };
};

// calculate rate based on usd value
export const USDRateValue = (amount, id) => {
  return (dispatch) => {
    dispatch({
      type: "USDRateCalculation",
      data: {
        amount,
        id
      },
    });
  };
};

// clear values to zero after trade or on load of page
export const clearCryptoValues = () =>{
  return dispatch =>{
      dispatch({type: 'clearCryptoValues'})
  }
}