import React,{useState, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './starttrade.module.css'
import { useFormik } from "formik";
import {tradeValidator} from '../../../validationSchema/validator'
import alert from '../../../assets/icons/alert-triangle.svg'
import Nigeria from '../../../assets/images/nigerialogo.svg'
import { connect } from 'react-redux';
import { clearTradeAmount, getRateCategory, getRateSubCategory, getTermsAndConditions, getUserRateValue } from '../../../store/actions/rate';
import cogoToast from 'cogo-toast';
import { clearCardTradeSuccess, createCardTrade } from '../../../store/actions/trade';
import Modal from "../../../components/Modals/FilterModal";
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';


const CardTradePage = ({fetchCategory, fetchSubCategory, category,
     subcategory, calcRate, amount, minAmount, maxAmount, getTerms, terms,
      accountDetails, handleStartTrade, loader, tradesuccess, clearTradeStatus, clearTradeAmount}) => {


  const navigate = useNavigate()

    const {
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldTouched
    } = useFormik({
        initialValues: {
        giftname: "",
        category: "",
        amount: "",
        comment: ""
        },
        validationSchema: tradeValidator,
        onSubmit(values, { setSubmitting }) {
            handSubmit(values, setSubmitting);
        },
    });

    useEffect(() =>{
        fetchCategory()
        clearTradeAmount()
    }, [fetchCategory, clearTradeAmount])

    const handleSubCategory = (val) =>{
        fetchSubCategory(val)
        setFieldValue("category", "")
        clearTradeAmount()
    }

    const handleCalculation = (amount, categoryId, giftname) =>{
        if(giftname === ""){
            setFieldTouched("giftname", true);
        }
        else if(categoryId === ""){
            setFieldTouched("category", true);
        }
        else{
            calcRate(amount, categoryId)
        }
        
    }
    

    const [preview, setPreview] = useState([]);
    const fileobj= [];


    const [showModal, setShowModal] = useState(false)

    const [tradeval, setTradeVal] = useState({});
 

    const changedHandler = event => {
        let files = event.target.files;
        fileobj.push(files);
        let reader;
    
        for (var i = 0; i < fileobj[0].length; i++) {
            reader = new FileReader();
            reader.readAsDataURL(fileobj[0][i]);
            reader.onload = event => {
            preview.push(event.target.result);   // update the array instead of replacing the entire value of preview
    
            setPreview([...new Set(preview)]); // spread into a new array to trigger rerender
            } 
        } 
    }

    const removeImage = (index) =>{
        console.log(index)
        const result = [...preview];
      
        // removing the element using splice
        result.splice(index, 1);
        // updating the list
        setPreview(result);
      }

    const handSubmit = async (values) =>{
        var res = {
            ...values,
            categoryId: values.giftname,
            subCategoryId: values.category,
            cardAmount: parseFloat(values.amount),
            amount: amount,
            imageUrl: preview
        }
    
        // check if amount entered is less than minimum expected or greater than maximum expected
        if(parseFloat(values.amount) < minAmount) {
            cogoToast.warn(`The minimum amount for this subcategory is $${minAmount}`)
        } 
        else if(parseFloat(values.amount) > maxAmount){
            cogoToast.warn(`The maximum amount for this subcategory is $${maxAmount}`)
        }
        // check if at least an image of the card is added
        else if(preview.length === 0){
            cogoToast.warn('Kindly attach at least a giftcard!!!')
        }
        else if(preview.length === 11){
          cogoToast.warn('The maximum you can attach is 10 for a trade initialization')
        }
        else{
            // filter by category id and get terms and conditions for a trade
            let id = values.category
            getTerms(id)
            // shows terms and condition modal
            setShowModal(true)
            setTradeVal(res)
        }
    }

    //   confirm a trade
    const confirmTrade = () =>{
        // check if user has a default account filled before starting trade
        if(accountDetails.length === 0){
            cogoToast.info('Please add at least a bank account in the Accounts tab', {position: 'top-right'})
        }
        else{
            // starts and sends data for trade
            handleStartTrade(tradeval)        
        } 
    }

    // after a successful trade, route to trades page 
     useEffect(() =>{
        if(tradesuccess){
            setTimeout(() => {
                navigate('/trades')
                clearTradeStatus()
                clearTradeAmount()
            }, 2000);
        }
    }, [tradesuccess, navigate, clearTradeStatus, clearTradeAmount])

    return ( 
        <>
            {/* terms and conditions modal */}
            <Modal
              title="Terms & Conditions"
              show={showModal}
              onClose={() => setShowModal(false)}
            >   
            <div>

            { parse(terms) }  

            <div className="text-center mt-4 ">
                <button
                type="submit"
                onClick={confirmTrade}
                disabled={loader}
                className={`btn ${styles.btn_trade}`}
                >
                  Confirm Trade
                </button>
            </div>
           
          </div>

           
          </Modal>

         <UserSidebar>
            <div>
                <p className="titlehead">Trade Cards</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className={`mt-5 ${styles.giftcards_div}`}>
 
                            <form onSubmit={handleSubmit}>
                                <div className='row mt-3 mt-lg-0'>
                                    <div className='col-lg-6'>
                                        <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor="giftname">Gift Card Name</label>
                                                <select
                                                    defaultValue=""
                                                    name="giftname"
                                                    values={values.giftname}
                                                    onChange={(e) => {
                                                    handleChange(e)
                                                    handleSubCategory(e.currentTarget.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    className={`${styles.input_style}`}
                                                    id="giftname">
                                                <option value="" disabled>Select</option>        
                                                {category.map((opt, index) => {
                                                    return <option key={index} value={opt._id}>{opt.categoryname}</option>
                                                 })}        
                                            </select>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.giftname && errors.giftname}
                                            </small>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className={`mb-3 ${styles.input_container}`}>
                                                <label htmlFor="category">Gift Card Category</label>
                                                    <select
                                                        defaultValue={values.category}
                                                        name="category"
                                                        values={values.category}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`${styles.input_style}`}
                                                        id="category">
                                                    <option selected={values.category === ""} value="" disabled>Select</option>  
                                                    {subcategory.map((opt, index) => {
                                                        return <option key={index} value={opt._id}>{opt.subcategoryname}</option>
                                                    })}      
                                                </select>
                                                <small style={{ color: "#dc3545" }}>
                                                    {touched.category && errors.category}
                                                </small>
                                            </div>
                                    </div>

                                    <div className='col-lg-6'>
                                      <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor="amount">Amount</label>
                                            <div>
                                                <input
                                                    className={`${styles.input_style}`}
                                                    type="text"
                                                    placeholder="Amount in USD"
                                                    id="amount"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCalculation(e.currentTarget.value, values.category, values.giftname);
                                                      }}
                                                    value={values.amount}
                                                />
                                            </div>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.amount && errors.amount}
                                            </small>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="amount">You Get</label>
                                              <div className={styles.amount_style}>
                                                    <div className={styles.amount_div}>
                                                        <div>
                                                            <img src={Nigeria} style={{width: '25px', height:"25px"}} alt="nigeria" />
                                                        </div>
                                                        <div className="ml-2">
                                                            <p className="mb-0" style={{color: '#2C3A50', fontWeight: 'bold'}}>NGN</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    className={`${styles.amount_input}`}
                                                    type="text"
                                                    placeholder="0.00"
                                                    id="amount"
                                                    disabled
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={amount}
                                             />
                                           
                                        </div>
                                     
                                    </div>

                                    <div className='col-lg-6'>

                                        <div className={`mb-3 ${styles.input_container}`}>
                                                <label htmlFor="comment">Comment</label>
                                                <div>
                                                    <textarea
                                                        className={`${styles.input_style}`}
                                                        type="text"
                                                        placeholder="Enter code(s) if you're trading ecode or if your card is not clear."
                                                        id="comment"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.comment}
                                                        rows="4"
                                                        style={{resize: 'none'}}
                                                    />
                                                </div>
                                                <small style={{ color: "#dc3545" }}>
                                                    {touched.comment && errors.comment}
                                                </small>
                                            </div>
                                        
                                        </div>

                                </div>

                                <div className={`mt-3 ${styles.alert_info}`}>
                                    <img src={alert} className="img-fluid mr-2" alt='alert'></img>
                                    <p className={styles.alert_title}>Please upload the cards image to start a trade</p>
                                </div>
                                
                                <div className='row mt-3'>
                                    <div className='col-lg-6'>
                                        <div className={`mb-3 ${styles.input_container}`}>
                                           <div className={`${styles.input_group} ${styles.cursor_pointer}`}>
                                             <div className={styles.custom_file}>
                                                <input accept="image/*"        
                                                    multiple
                                                    onChange={changedHandler}
                                                    className={styles.custom_file_input} type="file" />
                                                <label className={styles.custom_file_label}>
                                                    Add your file(s)
                                                </label>
                                            </div>
                                            </div>         
                                        </div> 
                                    </div>
                                </div>

                                <div className={styles.fileupload}>
                                    <div className="row">
                                        
                                        {(preview || []).map((url, index) => (
                                            <div className="col-3 mb-3">
                                                 <div className={`${styles.imageFile}`}>
                                                    <span className={styles.close_icon}
                                                     onClick={() => removeImage(index)}
                                                    ></span>
                                                    <img src={url} alt="..." key={index} 
                                                    style={{
                                                        maxWidth: '100%',
                                                        height: '100px',
                                                    borderRadius: '4px'}} />
                                                </div>
                                             </div>
                                            ))}    
                                    </div>                                    
                                </div>
                              

                                <div className="text-center mt-5">
                                    <button 
                                    type="submit"
                                    disabled={loader}
                                    className={`btn ${styles.btn_trade}`}>Start Trade</button>
                                </div>
                            </form>



                </div>


             </div>
        </div>
        </>
     );
}




const mapStateToProps = (state) =>{
    return{
        category: state.rate.category,
        subcategory: state.rate.subcategory,
        amount: state.rate.tradeamount,
        minAmount: state.rate.userminAmount,
        maxAmount: state.rate.usermaxAmount,
        terms: state.rate.terms,
        accountDetails: state.auth.accountDetails,
        loader: state.trade.btnLoader,
        tradesuccess: state.trade.trade_success
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetchCategory: () => dispatch(getRateCategory()),
        fetchSubCategory: (id) => dispatch(getRateSubCategory(id)),   
        calcRate: (amount, id) => dispatch(getUserRateValue(amount, id)),
        handleStartTrade: (values) => dispatch(createCardTrade(values)),
        getTerms: (id) => dispatch(getTermsAndConditions(id)),
        clearTradeStatus: () => dispatch(clearCardTradeSuccess()),
        clearTradeAmount : () => dispatch(clearTradeAmount()),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CardTradePage);