import React from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './trade.module.css'
import sent_circle from '../../../assets/icons/sent_circle.svg'
import get_circle from '../../../assets/icons/get_circle.svg'
import { Link } from 'react-router-dom';
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "react-moment";


const GiftcardDetails = ({trades}) => {

    const {id} = useParams()

    const trade = trades.find((val) => val._id === id);


      // mapping images
    const imageLayout = trade.imageUrl
    .filter((el) => el !== "")
    .map((item, index) => (
        <div key={index} className='col-lg-6 mb-3'>
         <div>
            <ImageZoom>
                <img
                alt="cards"
                src={item}
                className={styles.cardImage}
                />
            </ImageZoom>
         </div>
        </div>
    ));

    
    

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_badge
            break;
          case "Completed":
            result = styles.success_badge
            break;
          case "Declined":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };

    const numberWithCommas = (x) => { 
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }
      

    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Trade Details</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className={`mt-5 ${styles.details_card}`}>

                        <Link to="/trades" className={styles.goback_div}>
                            <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                            <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                        </Link>

                    <div className='row mt-4'>
                        <div className='col-lg-6 mb-4 mb-lg-0'>
                            <div className={styles.sent_card}>
                                <div className={styles.sent_sect}>
                                    <div className='mr-4'>
                                        <img src={sent_circle} alt="circle" />
                                    </div>
                                    <div>
                                        <h6 className={styles.sent_title}>You Sent</h6>
                                        <h5 className={styles.sent_subtitle}>USD {trade.cardAmount}</h5>
                                    </div>
                                </div>       
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className={styles.sent_card}>
                                <div className={styles.sent_sect}>
                                    <div className='mr-4'>
                                        <img src={get_circle} alt="circle" />
                                    </div>
                                    <div>
                                        <h6 className={styles.sent_title}>You Get</h6>
                                        <h5 className={styles.sent_subtitle}>NGN {numberWithCommas(trade.amount)}</h5>
                                    </div>
                                </div>       
                            </div>
                        </div>

                    </div>

                    <div className='row mt-4'>
                        <div className='col-lg-6'>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Transaction Id</h6>
                                <p className={`mt-1 ${styles.details_value}`}>{trade._id}</p>
                            </div>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Trade Type</h6>
                                <p className={`mt-1 ${styles.details_value}`}>Giftcard</p>
                            </div>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Card Name</h6>
                                <p className={`mt-1 ${styles.details_value}`}>{trade.subCategoryDetails.categoryname}</p>
                            </div>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Card Category</h6>
                                <p className={`mt-1 ${styles.details_value}`}>{trade.subCategoryDetails.subcategoryname}</p>
                            </div>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Comment</h6>
                                <p className={`mt-1 ${styles.details_value}`}>{trade.comment}</p>
                            </div>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Date</h6>
                                <p className={`mt-1 ${styles.details_value}`}>
                                        <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                {trade.createdAt}
                                </Moment>
                                    
                              </p>
                            </div>

                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Trade Status</h6>
                                <div className='d-flex'>
                                     <p className={`mt-2 ${styles.details_value} ${getStatusColor(trade.tradeStatus)}`}>{trade.tradeStatus}</p>
                                </div>
                                
                            </div>


                        </div>

                        <div className='col-lg-6'>

                            <div>
                              <h6 className={styles.details_title}>Uploaded Cards</h6> 
                            </div>

                            {/* cards */}
                            <div className='row mt-3 mb-3'>
                                {
                                    imageLayout
                                }

                            </div>

                            {
                          trade.tradeStatus === 'Declined' &&
                          <>
                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Declined Reason</h6>
                                <p className={styles.details_value}>{trade.hasOwnProperty('declinedReason') ? trade.declinedReason.reasonText : "Declined"}</p>
                            </div>
                              
                            <div className='mb-4'>
                                <h6 className={styles.details_title}>Images Attached for Declination</h6>
                                <ImageZoom>
                                    <img
                                    alt="cards"
                                    src={trade.declinedReason.imageUrl === "" ? trade.imageUrl[0] : trade.declinedReason.imageUrl }
                                    className={`mt-2 ${styles.cardImage}`}
                                    />
                                </ImageZoom>
                            </div>
                          </>
                          }


                        </div>

                    </div>

                    <div className="text-center mt-lg-4 mt-5">
                      <Link
                        type="submit"
                        className={`btn ${styles.btn_start}`}
                        to="/start-trade/giftcard"
                      >
                        Start Another Trade
                      </Link>
                    </div>

                </div>

                
            </div>
        </div>
        </>
     );
}



const mapStateToProps = (state) => {
    return {
      trades: state.trade.trades,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(GiftcardDetails);