import React, {useEffect} from 'react';
import styles from './auth.module.css'
import logo_black from '../../assets/icons/iking-black.png'
import chevron from '../../assets/icons/chevron-left.svg'
import { Link } from 'react-router-dom';
import mail from '../../assets/icons/mail.svg'
import { resendLink } from '../../store/actions/auth';
import { connect } from 'react-redux';


const VerifyInfoPage = ({email, sendLink, link_status}) => {

    useEffect(() => {
        document.body.classList.add("userBg");
      }, []);

    const handleSendAgain = () => {
        if (email !== "") {
           const creds = {
             email: email
           };
          sendLink(creds)
        }
    }

    return ( 
        <>
       <div className='contain'>
             <div className={styles.verify_div}>
                  <div>
                    <img src={logo_black} alt="logo" className={`img-fluid ${styles.logo}`} />
                </div>
                <Link to="/login" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                    <img src={chevron} alt="arrow" style={{width: '17px', height: '17px'}} />
                    <p className={styles.back_home_title}>Back to Login</p>
                </Link>
            </div>

            <div className={styles.verify_card_div}>
                <div className={styles.verify_card}>

                    <div className={styles.verify_icon_div}>
                        <img src={mail} alt="archive" /> 
                    </div>

                    <h3 className={`text-center mt-4 ${styles.verify_title}`}>Verify your account</h3>
                    <p  className={`text-center mt-2 ${styles.verify_subtitle}`}>
                    Account activation link has been sent to the e-mail 
                        address you provided
                    </p>

                    <div className={styles.verify_link_div}>
                        <p>Didn’t get the mail? <span 
                         onClick={handleSendAgain}
                         className={link_status ? `${styles.link_status} ${styles.link_disabled}` : `${styles.link_status}`}
                        >Send it again</span></p>
                    </div>

                </div>
            </div>
       </div>
        </>
     );
}
 

const mapStateToProps = (state) => {
    return {
      email: state.auth.signup_email,
      link_status: state.auth.link_status
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      sendLink: (creds) => dispatch(resendLink(creds)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(VerifyInfoPage);