import React,{useRef, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar'
import styles from './security.module.css'
import change_icon from '../../../assets/icons/change_pin.svg'
import reset_icon from '../../../assets/icons/reset_pin.svg'
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import { ResetTokenValidator } from "../../../validationSchema/validator";
import { connect } from 'react-redux';
import { clearValidToken, verifyResetPinCode } from '../../../store/actions/auth';


const ResetPinTokenPage = ({verifyCode, email, validtoken, clearToken}) => {

    const ref = useRef();

    const navigate = useNavigate()

    const handleSubmit = async (values) => {
        await verifyCode(values);
    };

    const maskEmail = (email = "") => {
        const [name, domain] = email.split("@");
        const { length: len } = name;
        const maskedName = name.substring(0,5) + "****" + name[len - 1];
        const maskedEmail = maskedName + "@" + domain;
        return maskedEmail;
    };

    useEffect(() => {
        if (validtoken) {
          // reset the form
          setTimeout(() => {
            clearToken();
            navigate("/security/reset-pin/set");
          }, 3000);
        }
     }, [validtoken, clearToken, navigate]);



    return ( 
        <>
            <UserSidebar>
                <div>
                    <p className="titlehead">Security</p>
                </div>
            </UserSidebar>
            <div className="usermain">
            <div className="contain-side">

                <div className='mt-5 mb-5'>

                    <h6 className={styles.managepin_title}>Manage Pin</h6>

                    <div className="row mt-4">
                        <div className="col-lg-4">

                            <div className={styles.change_pin_div}>
                                <div className="text-center">
                                    <img
                                    src={change_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                    Change your pin
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/change-pin"
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Change Pin
                                    </Link>
                                </div>
                            </div>

                            <div className={`mt-4 ${styles.change_pin_div}`}>
                                <div className="text-center">
                                    <img
                                    src={reset_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                   Forgot Your Pin?
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/reset-pin"
                                    className={`btn ${styles.btn_change_active}`}
                                    >
                                    Reset Pin
                                    </Link>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-8 mt-lg-0 mt-4'>

                            <div className={styles.change_pin_form}>

                                <h6 className={`text-center ${styles.managepin_title}`}> Please provide the token sent to {maskEmail(email)}</h6>
                                
                                <div className="mt-4">
                                    {/* form submission */}
                                    <Formik
                                    onSubmit={(values, { setSubmitting }) =>
                                        handleSubmit(values, setSubmitting)
                                    }
                                    validationSchema={ResetTokenValidator}
                                    initialValues={{
                                        code: ""
                                    }}
                                    >
                                    {({
                                        handleChange,
                                        isSubmitting,
                                        handleSubmit,
                                        handleBlur,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                        <Form ref={ref} onSubmit={handleSubmit}>
                            
                                        {/* confirm password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="code">
                                               Token
                                            </label>
                                            <input
                                                type="text"
                                                className={`${styles.input_style}`}
                                                name="code"
                                                placeholder="Token"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="code"
                                                value={values.code}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.code && errors.code}
                                            </small>
                                            </div>
                                        </div>

                                        <div className="text-center mt-4">
                                            <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={`btn ${styles.btn_submit}`}
                                            >
                                            Next
                                            </button>
                                        </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      email: state.auth.profile.email,
      validtoken: state.auth.validtoken,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      verifyCode: (values) => dispatch(verifyResetPinCode(values)),
      clearToken: () => dispatch(clearValidToken()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(ResetPinTokenPage);