import React,{useEffect} from 'react';
import styles from './notfound.module.css'
import logo_black from '../../assets/icons/iking-black.png'
import chevron from '../../assets/icons/chevron-left.svg'
import archive from '../../assets/icons/archive.svg'
import { Link } from 'react-router-dom';


const NotFoundPage = () => {

    
  useEffect(() => {
    document.body.classList.add("userBg");
  }, []);

    return (  
        <>
        <div className='contain'>

            <div className={styles.not_found_div}>
                <div>
                    <img src={logo_black} alt="logo" className='img-fluid' />
                </div>
                <Link to="/" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                    <img src={chevron} alt="arrow" style={{width: '17px', height: '17px'}} />
                    <p className={styles.back_home_title}>Back to Home</p>
                </Link>
            </div>

            <div className={styles.not_found_card_div}>
                <div className={styles.not_found_card}>

                    <div className={styles.not_found_icon_div}>
                        <img src={archive} alt="archive" /> 
                    </div>

                    <h1 className={`text-center mt-4 ${styles.title}`}>404</h1>
                    <p  className={`text-center mt-2 ${styles.subtitle}`}>Sorry, we have looked for the page you
                            requested, but we can’t find it!</p>

                    <div className={styles.not_found_link}>
                        <Link to="/">You can start from the Home page</Link>
                    </div>

                </div>

            </div>

        </div>
        </>
    );
}
 
export default NotFoundPage;