import React, {useEffect, useState} from 'react';
import styles from './auth.module.css'
import { Form, Formik } from "formik";
import { registerValidator } from "../../validationSchema/validator";
import { Link, useNavigate } from "react-router-dom";
import eye_off from '../../assets/icons/eye-off.svg'
import email_icon from '../../assets/icons/email_icon.svg'
import OnboardLeftLayout from '../../components/OnboardingSideBar/sidebar';
import logo_black from '../../assets/icons/iking-black.png'
import eye from '../../assets/icons/eye.svg'
import { connect } from 'react-redux';
import { clearSignUp, signUp } from '../../store/actions/auth';

const RegisterPage = ({register, clearRegistration, signup_success}) => {

    const [passwordShown, setPasswordShown] = useState(false);

    const navigate = useNavigate()

    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setconfirmPasswordShown(confirmpasswordShown ? false : true);
      };

     // register func
   const handleSubmit = async (values) => {
        console.log(values)
        await register(values)
    };

    useEffect(()=>{
        if (signup_success) {
            navigate("/verifyaccount");
            setTimeout(() => {
              clearRegistration();
            }, 2000);
        }  
    },[signup_success, clearRegistration, navigate])


    return (  
        <>
        <div className='row no-gutters'>
           <OnboardLeftLayout />
            <div className='col-lg-5'>
                <div className={styles.auth_right_section}>

                <Link to="/" className='d-md-none d-sm-block'>
                        <img src={logo_black} alt="logo" className={`img-fluid ${styles.logo}`} />
                    </Link>

                    <div className='mt-4 mt-md-0'>
                        <h1 className={styles.right_title}>Hello Again!</h1>
                        <p className={`mt-1 ${styles.right_subtitle}`}>Sign Up to Get Started</p>
                    </div>

                    {/* form submission */}
                    <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={registerValidator}
                    initialValues={{ email: "", password: "", firstname: "", lastname: "", phone: "", confirm_password: "" }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                        {/* form section */}
                        <div className="mt-3 mt-md-2">

                             {/* first Name */}
                             <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="firstname">First Name</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="text"
                                    placeholder="John"
                                    id="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstname}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.firstname && errors.firstname}
                            </small>
                            </div>

                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="lastname">Last Name</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="text"
                                    placeholder="Eze"
                                    id="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastname}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.lastname && errors.lastname}
                            </small>
                            </div>

                            {/* email address */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="email">Email</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="email"
                                    placeholder="johndoe@email.com"
                                    id="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                 <img
                                    src={email_icon}
                                    alt="email"
                                    className={`${styles.email_style}`}
                                    />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.email && errors.email}
                            </small>
                            </div>

                            {/* phone number */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="phone">Phone Number</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="text"
                                    placeholder="0800-000-000"
                                    id="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.phone && errors.phone}
                            </small>
                            </div>

                            {/* password */}
                         <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="password">Password</label>

                            <div style={{ position: "relative" }}>
                                <input
                                className={`${styles.input_style}`}
                                placeholder="***********"
                                id="password"
                                type={passwordShown ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                 {
                                passwordShown
                                ? 
                                <img
                                src={eye}
                                alt="eye"
                                onClick={togglePasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                                :
                                <img
                                src={eye_off}
                                alt="eye"
                                onClick={togglePasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                                 }
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.password && errors.password}
                            </small>
                            </div>

                            {/* confirm password */}
                            <div className={`mb-2 ${styles.input_container}`}>
                            <label htmlFor="confirm_password">Confirm Password</label>

                            <div style={{ position: "relative" }}>
                                <input
                                className={`${styles.input_style}`}
                                placeholder="***********"
                                id="confirm_password"
                                type={confirmpasswordShown ? "text" : "password"}
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                             {
                                confirmpasswordShown
                                ? 
                                <img
                                src={eye}
                                alt="eye"
                                onClick={togglePasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                                :

                                <img
                                src={eye_off}
                                alt="eye"
                                onClick={toggleConfirmPasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                                 }
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.confirm_password && errors.confirm_password}
                            </small>
                            </div>

                            <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn ${styles.btn_register_submit}`}
                            >
                                Sign Up
                            </button>
                            </div>

                            <div className="mt-2">
                            <p className={`text-center ${styles.login_link}`}>
                                Already have an account?{" "}
                                <Link to="/login">Sign In</Link>
                            </p>
                            </div>
                        </div>
                        </Form>
                    )}
                    </Formik>
                        
                </div>
            </div>

        </div>
        </>
    );
}
 

const mapStateToProps = (state) =>{
    return{
        signup_success: state.auth.signup_success,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        register: (creds) => dispatch(signUp(creds)),
        clearRegistration: (creds) => dispatch(clearSignUp(creds)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);