import React, {useRef, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './security.module.css'
import change_icon from '../../../assets/icons/change_pin.svg'
import reset_icon from '../../../assets/icons/reset_pin.svg'
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import { ResetPinValidator } from "../../../validationSchema/validator";
import { connect } from 'react-redux';
import { clearForgetPinSuccess, ForgetPin } from '../../../store/actions/auth';


const ResetPinPage = ({forgetPin, clearForgetPin, forgetpinsuccess}) => {

    const ref = useRef();

    const navigate = useNavigate()

    const handleSubmit = async (values) => {
        console.log(values);
        await forgetPin(values);
    };

    useEffect(() => {
        if (forgetpinsuccess) {
          // reset the form
          setTimeout(() => {
            clearForgetPin();
            navigate("/security/reset-pin/token");
          }, 3000);
        }
      }, [forgetpinsuccess, clearForgetPin, navigate]);
    
    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Security</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className='mt-5 mb-5'>

                    <h6 className={styles.managepin_title}>Manage Pin</h6>

                    <div className="row mt-4">
                        <div className="col-lg-4">

                            <div className={styles.change_pin_div}>
                                <div className="text-center">
                                    <img
                                    src={change_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                    Change your pin
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/change-pin"
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Change Pin
                                    </Link>
                                </div>
                            </div>

                            <div className={`mt-4 ${styles.change_pin_div}`}>
                                <div className="text-center">
                                    <img
                                    src={reset_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                   Forgot Your Pin?
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/reset-pin"
                                    className={`btn ${styles.btn_change_active}`}
                                    >
                                    Reset Pin
                                    </Link>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-8 mt-lg-0 mt-4'>

                            <div className={styles.change_pin_form}>

                                <h6 className={styles.managepin_title}>Reset Pin</h6>
                                
                                <div className="mt-4">
                                    {/* form submission */}
                                    <Formik
                                    onSubmit={(values, { setSubmitting }) =>
                                        handleSubmit(values, setSubmitting)
                                    }
                                    validationSchema={ResetPinValidator}
                                    initialValues={{
                                        email:"",
                                        phoneNumber: "",
                                    }}
                                    >
                                    {({
                                        handleChange,
                                        isSubmitting,
                                        handleSubmit,
                                        handleBlur,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                        <Form ref={ref} onSubmit={handleSubmit}>
                                       

                                        {/* new password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="email">Email Address</label>
                                            <input
                                                type="email"
                                                className={`${styles.input_style}`}
                                                name="email"
                                                placeholder="Email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="email"
                                                value={values.email}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.email && errors.email}
                                            </small>
                                            </div>
                                        </div>

                                        {/* confirm password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="phoneNumber">
                                               Phone Number
                                            </label>
                                            <input
                                                type="text"
                                                className={`${styles.input_style}`}
                                                name="phoneNumber"
                                                placeholder="Phone Number"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="phoneNumber"
                                                value={values.phoneNumber}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.phoneNumber && errors.phoneNumber}
                                            </small>
                                            </div>
                                        </div>

                                        <div className="text-center mt-4">
                                            <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={`btn ${styles.btn_submit}`}
                                            >
                                            Next
                                            </button>
                                        </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      forgetpinsuccess: state.auth.forgetpinsuccess,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      forgetPin: (values) => dispatch(ForgetPin(values)),
      clearForgetPin: () => dispatch(clearForgetPinSuccess()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(ResetPinPage);