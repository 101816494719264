import React,{useRef, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './security.module.css'
import change_icon from '../../../assets/icons/change_pin.svg'
import reset_icon from '../../../assets/icons/reset_pin.svg'
import { Link } from 'react-router-dom';
import { Form, Formik } from "formik";
import { ChangePinValidator } from "../../../validationSchema/validator";
import { ChangePin, clearPinSuccess } from '../../../store/actions/auth';
import { connect } from 'react-redux';

const ChangePinPage = ({savePin, pinsuccess, clearPin}) => {

    const ref = useRef();

    const handleSubmit = async (values) => {
        console.log(values);
        await savePin(values);
    };

    useEffect(() => {
        if (pinsuccess) {
          // reset the form
          setTimeout(() => {
            ref.current.reset();
            clearPin();
          }, 3000);
        }
      }, [pinsuccess, clearPin]);
    

    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Security</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className='mt-5 mb-5'>

                    <h6 className={styles.managepin_title}>Manage Pin</h6>

                    <div className="row mt-4">
                        <div className="col-lg-4">

                            <div className={styles.change_pin_div}>
                                <div className="text-center">
                                    <img
                                    src={change_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                    Change your pin
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/change-pin"
                                    className={`btn ${styles.btn_change_active}`}
                                    >
                                    Change Pin
                                    </Link>
                                </div>
                            </div>

                            <div className={`mt-4 ${styles.change_pin_div}`}>
                                <div className="text-center">
                                    <img
                                    src={reset_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                   Forgot Your Pin?
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/reset-pin"
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Reset Pin
                                    </Link>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-8 mt-lg-0 mt-4'>

                            <div className={styles.change_pin_form}>

                                <h6 className={styles.managepin_title}>Change Pin</h6>
                                
                                <div className="mt-4">
                                    {/* form submission */}
                                    <Formik
                                    onSubmit={(values, { setSubmitting }) =>
                                        handleSubmit(values, setSubmitting)
                                    }
                                    validationSchema={ChangePinValidator}
                                    initialValues={{
                                        newpin: "",
                                        pin: "",
                                        confirm_pin: "",
                                    }}
                                    >
                                    {({
                                        handleChange,
                                        isSubmitting,
                                        handleSubmit,
                                        handleBlur,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                        <Form ref={ref} onSubmit={handleSubmit}>
                                        {/* current password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="pin">Old Pin</label>
                                            <input
                                                type="password"
                                                name="pin"
                                                placeholder="Pin"
                                                className={`${styles.input_style}`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="pin"
                                                value={values.pin}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.pin && errors.pin}
                                            </small>
                                            </div>
                                        </div>

                                        {/* new password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="newpin">New Pin</label>
                                            <input
                                                type="password"
                                                className={`${styles.input_style}`}
                                                name="newpin"
                                                placeholder="New pin"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="newpin"
                                                value={values.newpin}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.newpin && errors.newpin}
                                            </small>
                                            </div>
                                        </div>

                                        {/* confirm password */}
                                        <div>
                                            <div
                                           className={`mb-3 ${styles.input_container}`}
                                            style={{ position: "relative" }}
                                            >
                                            <label htmlFor="confirm_pin">
                                                Confirm new Pin
                                            </label>
                                            <input
                                                type="password"
                                                className={`${styles.input_style}`}
                                                name="confirm_pin"
                                                placeholder="Confirm new pin"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                id="confirm_pin"
                                                value={values.confirm_pin}
                                            />

                                            <small style={{ color: "#dc3545" }}>
                                                {touched.confirm_pin && errors.confirm_pin}
                                            </small>
                                            </div>
                                        </div>

                                        <div className="text-center mt-4">
                                            <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={`btn ${styles.btn_submit}`}
                                            >
                                            Change Pin
                                            </button>
                                        </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
      pinsuccess: state.auth.pinsuccess,
      isAuthenticated: state.auth.isAuthenticated,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      savePin: (values) => dispatch(ChangePin(values)),
      clearPin: () => dispatch(clearPinSuccess()),
    };
  }
   
 
export default connect(mapStateToProps, mapDispatchToProps)(ChangePinPage);