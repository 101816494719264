import React from 'react';
import styles from './landing.module.css'
import img1 from '../../assets/images/img1.png'
import { Link } from 'react-router-dom';

const InfoPage = () => {
    return ( 
        <>
        <div className={styles.info_div}>
            <div className='contain'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div>
                            <img src={img1} className="img-fluid" alt="info" />
                        </div>
                    </div>
                    <div className='col-lg-6'>

                        <div className={`mt-5 mt-lg-0 ${styles.info_first}`}>
                            <h3 className={styles.info_title}>Receive payment without hazzle</h3>
                            <p className={`mt-2 mt-lg-2 ${styles.info_subtitle}`}>
                            Delays for confirmation are now a thing of the past. You drop, WE PAY.</p>

                            <div className='mt-3 mt-lg-2'>
                                <Link to="/register" className={`btn ${styles.info_btn}`}>Start Trading</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        </>
     );
}
 
export default InfoPage;