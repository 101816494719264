import React from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './security.module.css'
import change_icon from '../../../assets/icons/change_pin.svg'
import reset_icon from '../../../assets/icons/reset_pin.svg'
import { Link } from 'react-router-dom';

const UsersManagePinPage = () => {
    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Security</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className='mt-5 mb-5'>

                    <h6 className={styles.managepin_title}>Manage Pin</h6>

                    <div className="row mt-4">
                        <div className="col-lg-4">

                            <div className={styles.change_pin_div}>
                                <div className="text-center">
                                    <img
                                    src={change_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                    Change your pin
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/change-pin"
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Change Pin
                                    </Link>
                                </div>
                            </div>

                            <div className={`mt-4 ${styles.change_pin_div}`}>
                                <div className="text-center">
                                    <img
                                    src={reset_icon}
                                    width="100"
                                    height="100"
                                    alt="pin shield"
                                    />
                                </div>

                                <div className="text-center">
                                    <p style={{ color: "rgba(26, 55, 78, 0.6)", fontSize: 16, letterSpacing: '0.342857px' }}>
                                   Forgot Your Pin?
                                    </p>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                    to="/security/reset-pin"
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Reset Pin
                                    </Link>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-8 mt-lg-0 mt-4'>

                            <div className={styles.manage_pin_div}>
                                
                                <h3 className={styles.title}>Easily manage your pin!</h3>
                                <p className={`mt-2 ${styles.subtitle}`}>Kindly use any of the options above to manage your transaction pin.</p>

                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
        </>
     );
}
 
export default UsersManagePinPage;