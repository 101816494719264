import React, {useState, useEffect} from 'react';
import styles from './landing.module.css'
import Logo from '../../assets/icons/iking-black.png'
import { HashLink as Link } from "react-router-hash-link";

const Navbar = () => {

    const [navShow, setnavShow] = useState(false);

    const [scroll,setScroll] = useState(1);

    const showMenu = () => {
        setnavShow(true);
      }

      
  const hideMenu = () => {
    setnavShow(false)
  }

    // running on mount
useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    }
  
  // setting the event handler from web API
  document.addEventListener("scroll", onScroll)
  
  // cleaning up from the web API
   return () => {
     document.removeEventListener("scroll", onScroll)
    }
  }, [scroll, setScroll])
  


    return (  
        <>
        <header className={
            scroll ? styles.nav_header : `${styles.nav_header} ${styles.scrolled}`}>
            <div className='contain'>
                <nav>
                     {/* logo */}
                    <div>
                    <Link to="/">
                        <img src={Logo} className="img-fluid" alt="Ikinghub Logo" />
                    </Link>
                    </div>

                 {/* hamburger for mobile menu(OPEN)  */}
                    <div className="d-block d-md-none" id="open" onClick={showMenu}>
                    {/* open icon */}
                    <svg
                        className={styles.open_icon}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                    </div>

                    {/* links for tablet and higher screens */}
                <ul className={styles.nav_links_head}>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                        <Link to="/#faq">FAQS</Link>
                    </li>
                    <li>
                        <a
                        className=""
                        target="_blank"
                        rel="noreferrer"
                        href="https://blog.ikinghub.com/"
                        >
                        Blog
                        </a>
                    </li>
                    <li>
                        <Link to="/#rate">Rate Calculator</Link>
                    </li>
                 </ul>

                    {/* links for mobile users */}
                    <div
                    className={
                        navShow ? 
                        `d-block d-md-none ${styles.mobile_menu}` : "d-none"}
                    >
                    <div className={styles.mobile_menu_div}>
                        <div className={styles.mobile_container}>
                        <div>
                            <Link to="/">
                            <img src={Logo} className="img-fluid" alt="Dart Logo" />
                            </Link>
                        </div>

                        {/* hamburger for close menu(close)  */}
                        <div id="close" onClick={hideMenu}>
                            {/* open icon */}
                            <svg
                            className={styles.open_icon}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                            </svg>
                        </div>
                        </div>

                        {/* mobile links */}
                        <div className={styles.mobile_links}>
                        <Link to="/">Home</Link>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="/#faq">FAQs</Link>
                        <a
                            className=""
                            target="_blank"
                            href="https://blog.ikinghub.com/"
                            rel="noreferrer"
                        >
                            Blog
                        </a>
                        <Link to="/">Rate Calculator</Link>
                        </div>
                    </div>
                    </div>
                </nav>
            </div>

        </header>
        </>
    );
}
 
export default Navbar;