import React, { useEffect } from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './starttrade.module.css'
import crypto from '../../../assets/icons/sellbitcoinImg.svg'
import { useFormik } from "formik";
import { cryptoRateValidator } from "../../../validationSchema/validator";
import { connect } from 'react-redux';
import { clearCryptoValues, getCoinRateValue, getCoinsRate, USDRateValue } from '../../../store/actions/rate';
import cogoToast from 'cogo-toast';
import { useNavigate } from 'react-router-dom';
import { clearCryptoTradeSuccess, createCryptoTrade } from '../../../store/actions/trade';

const CryptoTradePage = ({fetchCoins, coins, calcRate, coinAmount, amountToGet, usdAmount, clearVal, usdRate, create, cryptosuccess, clearSuccess}) => {
    
    const navigate = useNavigate()

    useEffect(()=>{
        fetchCoins()
        clearVal()
    },[fetchCoins, clearVal])


const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        handleSubmit,
      } = useFormik({
        initialValues: {
          coin: "",
          amount: "",
          usdamount:  "",
        },
        validationSchema: cryptoRateValidator,
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
});

 useEffect(() => {
    setFieldValue("usdamount", usdAmount);
    setFieldValue("amount", coinAmount);
  }, [usdAmount, coinAmount, setFieldValue]);

const handleCalculation = (amount, coin) => {
    var pattern = /^[0-9]*.?[0-9]*$/;
    var amountValid = pattern.test(amount);
    if (coin !== "") {
      if (amountValid && amount !== "") {
         calcRate(amount, coin);
      }
    } else {
        setFieldTouched("coin", true);
    }
  };

// use value from usd to get the coin value 
const handleRateCalc = (amount, coin) => {
    var pattern = /^[0-9]*.?[0-9]*$/;
    var amountValid = pattern.test(amount);
    if (coin !== "") {
        if (amountValid && amount !== "") {
        usdRate(amount, coin);
        }
    } else {
        setFieldTouched("coin", true);
    }
    };
    

const handSubmit = async (values, setSubmitting) => {
    setSubmitting(true)
    // check for usd amount if it is lesser than $20
    var usdAmount = values.usdamount;
    if (usdAmount < 20) {
      cogoToast.error("The minimum amount you can trade is $20");
      setSubmitting(false)
    } else {
          //  get coin type
          var coinId = values.coin;
          var coinType = coins.find((pro) => pro._id === coinId).coinType;
          let creds = {
            coinType: coinType,
            narration: "Trade Coin",
            amounttInCoin: parseFloat(values.amount),
            amountInLocalCurrency: parseFloat(amountToGet.toFixed(2)),
            amountInUsd: parseFloat(values.usdamount.toFixed(2))
          };
          console.log(creds)

          await create(creds)

          setTimeout(()=>{
            setSubmitting(false)
          },3000)
              
    }
  };


  useEffect(()=>{
    if(cryptosuccess){
        setTimeout(()=>{
          clearSuccess()
          navigate('/start-trade/crypto/complete')
        }, 3000)
    }
  },[cryptosuccess,navigate, clearSuccess])

    return (  
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Trade Crypto</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className='row mt-5 mb-5'>
                    <div className='col-lg-4 mb-lg-0 mb-4'>
                        <div className={styles.coin_div}>
                           
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group input-container">
                                <label htmlFor="coin">Select Coin</label>
                                <select
                                    name="coin"
                                    values={values.coin}
                                    onChange={(e) => {
                                        clearVal();
                                        handleChange(
                                            e,
                                            setFieldValue("amount", ""),
                                            setFieldValue("usdamount", 0)
                                          );
                                       
                                        }}
                                    defaultValue=""
                                    onBlur={handleBlur}
                                    className={`${styles.input_style}`}
                                    id="coin"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    {coins.map((opt, index) => {
                                        return (
                                            <option key={index} value={opt._id}>
                                            {opt.coinType}
                                            </option>
                                        );
                                 })}                
                                </select>
                                <small style={{ color: "#dc3545" }}>
                                    {touched.coin && errors.coin}
                                </small>
                                </div>

                                <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                <label htmlFor="amount">
                                    Enter amount to calculate coins
                                </label>
                                <input
                                    value={values.amount}
                                    onChange={(e) => {
                                    handleChange(e);
                                    handleCalculation(e.currentTarget.value, values.coin);
                                    }}
                                    onBlur={handleBlur}
                                    id="amount"
                                    className={`${styles.input_style}`}
                                    placeholder="0.00"
                                    type="text"
                                />
                                <small style={{ color: "#dc3545" }}>
                                    {touched.amount && errors.amount}
                                </small>
                                </div>

                                <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="usdamount">USD Amount</label>
                                    <input
                                        value={values.usdamount}
                                        onChange={(e) => {
                                        handleChange(e);
                                        handleRateCalc(e.currentTarget.value, values.coin);
                                        }}
                                        onBlur={handleBlur}
                                        id="usdamount"
                                        className={`${styles.input_style}`}
                                        placeholder="0.00"
                                        type="text"
                                    />
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.usdamount && errors.usdamount}
                                    </small>
                                </div>

                                <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="amount">You get (Naira)</label>
                                    <input
                                         value={`NGN ${amountToGet
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="amount"
                                        className={`${styles.input_style}`}
                                        placeholder="NGN 0.00"
                                        disabled
                                        type="text"
                                       
                                    />
                                    </div>

                                <div className="text-center mt-4 mb-1">
                                <button
                                    type="submit"
                                    className={`btn ${styles.btn_sell}`}
                                    disabled={isSubmitting}
                                >
                                    Sell Crypto
                                </button>
                                </div>
                                <small>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Minimum amount is $20. <br/>Please trade all USDT on the BEP20 network to avoid loss of funds!
                                </small>
                            </form>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-8'>

                        <div className={styles.coin_result_div}>

                            <div className={styles.coin_img}>
                                <img src={crypto} alt="coin" style={{width: 150, height: 150}} />
                            </div>

                            <p className={styles.coin_text}>
                                Enter how much coins you would like to sell, to see how much
                                naira you will receive.
                                </p>

                        </div>

                    </div>

                </div>

            </div>
        
        </div>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
      coins: state.rate.coins,
      isAuthenticated: state.auth.isAuthenticated,
      amountToGet: state.rate.amountToGet,
      usdAmount: state.rate.usdAmount,
      coinAmount: state.rate.coinAmount,
      cryptosuccess: state.trade.cryptosuccess
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchCoins: () => dispatch(getCoinsRate()),
      calcRate: (amount, id) => dispatch(getCoinRateValue(amount, id)),
      usdRate: (amount, id) => dispatch(USDRateValue(amount, id)),
      clearVal: () => dispatch(clearCryptoValues()),
      clearSuccess: () => dispatch(clearCryptoTradeSuccess()),
      create: (val) => dispatch(createCryptoTrade(val)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(CryptoTradePage);