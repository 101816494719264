import React,{useEffect, useRef} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './trade.module.css'
import sent_circle from '../../../assets/icons/sent_circle.svg'
import get_circle from '../../../assets/icons/get_circle.svg'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from "react-moment";
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import { clearProof, uploadImage, UploadProof } from '../../../store/actions/trade';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CryptoDetails = ({trades, handleUpload, proofUpload, loader, imageAdded, proofUpdated, clearProof, imageLoader}) => {

    const {id} = useParams()

    const navigate = useNavigate()

    const trade = trades.find((val) => val._id === id);

    const fileInputRef = useRef();

    const fileobj= [];

    const changedHandler = () => {
        let files = fileInputRef.current.files;
        fileobj.push(files);
        handleUpload(files[0])
    }

    const handleUpdate = () =>{
        let url = imageAdded.hasOwnProperty('fileUrl') ? imageAdded.fileUrl: ""
        proofUpload(id, url)
    }

    useEffect(()=>{
        if(proofUpdated){
            setTimeout(() => {
                navigate('/trades')
                clearProof()
            }, 2000);
        }
    },[proofUpdated, navigate, clearProof])

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Created":
            result =  styles.pending_badge
            break;
          case "Confirmed":
            result = styles.success_badge
            break;
          case "Failed":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    return ( 
        <>
          <UserSidebar>
            <div>
                <p className="titlehead">Trade Details</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.details_card}`}>

                  <Link to="/trades" className={styles.goback_div}>
                            <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                            <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                    </Link>

                <div className='row mt-4'>
                    <div className='col-lg-6 mb-4 mb-lg-0'>
                        <div className={styles.sent_card}>
                            <div className={styles.sent_sect}>
                                <div className='mr-4'>
                                    <img src={sent_circle} alt="circle" />
                                </div>
                                <div>
                                    <h6 className={styles.sent_title}>You Created</h6>
                                    <h5 className={styles.sent_subtitle}>{trade.coinType} {trade.amounttInCoin}</h5>
                                </div>
                            </div>       
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className={styles.sent_card}>
                            <div className={styles.sent_sect}>
                                <div className='mr-4'>
                                    <img src={get_circle} alt="circle" />
                                </div>
                                <div>
                                    <h6 className={styles.sent_title}>You Get</h6>
                                    <h5 className={styles.sent_subtitle}>NGN {numberWithCommas(trade.amountInLocalCurrency)}</h5>
                                </div>
                            </div>       
                        </div>
                    </div>

                </div>

                <div className='row mt-4'>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Transaction Id</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{id}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Date</h6>
                            <p className={`mt-1 ${styles.details_value}`}>
                              <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                {trade.createdAt}
                                </Moment></p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Trade Type</h6>
                            <p className={`mt-1 ${styles.details_value}`}>Crypto</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Amount Traded</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{trade.amounttInCoin} {trade.coinType}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Coin Type</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{trade.coinType}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Wallet Address</h6>
                            <p className={`mt-1 ${styles.details_value}`}>{trade.walletAddress}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_title}>Trade Status</h6>
                            <div className='d-flex'>
                                <p className={`mt-2 ${styles.details_value} ${getStatusColor(trade.tradeStatus)}`}>{trade.tradeStatus}</p>
                            </div>
                            
                        </div>    
                    </div>


                 {
                    trade.tradeStatus === 'Created' &&
                   <div className='col-lg-6'>
                        <div className='mb-3'>
                          <h6 className={styles.details_title}>Payment Proof</h6>
                           {
                               trade.hasOwnProperty('paymentImagrUrl') ? 
                                    <ImageZoom>
                                    <img
                                    alt="cards"
                                    src={trade.paymentImagrUrl}
                                    className={`mt-2 ${styles.cardImage}`}
                                    style={{height: '100px'}}
                                    />
                                </ImageZoom> 
                                :
                             <div className={`mt-2 ${styles.input_group} ${styles.cursor_pointer}`}>
                                <div className={styles.custom_file}>
                                <input accept="image/*"        
                                     ref={fileInputRef}  
                                    onChange={changedHandler}
                                    className={styles.custom_file_input} type="file" />
                                <label className={styles.custom_file_label}>
                                    Attach a payment proof
                                </label>
                                </div>
                            </div>    

                           }
                                  
                        </div> 

                        <div className="row">                            
                            <div className='col-4 mb-3'>
                              {imageLoader &&  <Skeleton count={1} style={{height: '100px'}} /> }
                             {
                               imageAdded.hasOwnProperty('fileUrl') ?
                                <div className={styles.image_preview}>
                                  <img src={imageAdded.fileUrl} className='img-fluid' alt="preview" />
                                </div>
                              :
                              ""
                             } 
                              
                            </div>
                        </div>        
                    </div>

                    }
                </div>

                <div className={`mt-5 ${styles.cta_btn}`}>
                <Link
                    type="submit"
                    className={`btn ${styles.btn_start}`}
                    to="/start-trade/crypto"
                >
                    Start Another Trade
                </Link>

                {
                imageAdded.hasOwnProperty('fileUrl') ?
                 <button
                        type="submit"
                        className={`btn ${styles.btn_updateT}`}
                        disabled={loader}
                        onClick={() => {
                        handleUpdate();
                        }}
                    >
                       Update Trade
                    </button> 
                    :
                    ""
                }
                </div>


             </div>

                
            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      trades: state.trade.trades,
      loader: state.trade.updateLoad,
      imageLoader: state.trade.imageLoader,
      imageAdded: state.trade.imageAdded,
      proofUpdated: state.trade.proofUpdated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleUpload : (file, id) => dispatch(uploadImage(file, id)),
        proofUpload : (id, url) => dispatch(UploadProof(id, url)),
        clearProof : () => dispatch(clearProof()),
    };
  };
  
 
export default connect(mapStateToProps, mapDispatchToProps)(CryptoDetails);