import React,{useEffect} from 'react';
import Footer from '../../components/LandingComponents/Footer';
import Hero from '../../components/LandingComponents/Hero';
import InfoPage from '../../components/LandingComponents/Info';
import Navbar from '../../components/LandingComponents/Navbar';
import RateSection from '../../components/LandingComponents/Rate';
import FeaturesPage from '../../components/LandingComponents/Features';
import StepsPage from '../../components/LandingComponents/Steps';
import TestimonialSection from '../../components/LandingComponents/Testimonials';
import FAQSection from '../../components/LandingComponents/FAQ';


const LandingPageLayout = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//code.tidio.co/n9qi9wrz24qh9zz5l50r42h2xq8ze448.js";
        script.async = true;
        document.body.appendChild(script);
     }, []);

    return ( 
        <>
            <Navbar/>
            <Hero/>
            <RateSection />
            <FeaturesPage />
            <InfoPage />
            <StepsPage />
            <TestimonialSection />
            <FAQSection />
            <Footer/>
        </>
     );
}
 
export default LandingPageLayout;