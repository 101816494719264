import React from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './profile.module.css'
import { Form, Formik } from "formik";
import { ChangePasswordValidator } from "../../../validationSchema/validator";
import profile_icon from '../../../assets/images/userProfile.svg'

import message from '../../../assets/icons/Message.svg'
import call from '../../../assets/icons/Call.svg'
import { connect } from 'react-redux';
import { ChangePassword } from '../../../store/actions/auth';
import Moment from "react-moment";

const UsersProfilePage = ({profile, savePassword}) => {

    const handleSubmit = async (values) => {
        await savePassword(values)
    };

    return (  
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Profile</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className='row mt-5 mb-5'>
                <div className='col-lg-8'>

                    <div className={`${styles.profile_card}`}>

                        <h6 className={styles.profile_title}>Change Password</h6>

                        <div className='mt-4'>

                              {/* form submission */}
                            <Formik
                            onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                            }
                            validationSchema={ChangePasswordValidator}
                            initialValues={{
                                newpassword: "",
                                password: "",
                                confirm_password: "",
                            }}
                            >
                            {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                {/* current password */}
                                <div>
                                    <div
                                  className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="email">Current Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Current Password"
                                        style={{ color: "black" }}
                                        className={`${styles.input_style}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="password"
                                        value={values.password}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.password && errors.password}
                                    </small>
                                    </div>
                                </div>

                                {/* new password */}
                                <div>
                                    <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="email">New Password</label>
                                    <input
                                        type="password"
                                        className={`${styles.input_style}`}
                                        name="newpassword"
                                        placeholder="New Password"
                                        style={{ color: "black" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="newpassword"
                                        value={values.newpassword}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.newpassword && errors.newpassword}
                                    </small>
                                    </div>
                                </div>

                                {/* confirm password */}
                                <div>
                                    <div
                                   className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="email">Confirm New Password</label>
                                    <input
                                        type="password"
                                        className={`${styles.input_style}`}
                                        name="confirm_password"
                                        placeholder="Confirm New Password"
                                        style={{ color: "black" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="confirm_password"
                                        value={values.confirm_password}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.confirm_password &&
                                        errors.confirm_password}
                                    </small>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`btn ${styles.btn_change}`}
                                    >
                                    Change Password
                                    </button>
                                </div>
                                </Form>
                            )}
                            </Formik>
                        </div>

                    </div>

                 </div>
                 <div className='col-lg-4 mt-md-0 mt-4'>

                     <div className={`${styles.profile_card2}`}>

                         <div className='text-center'>

                             <div className={styles.profile_img_div}>
                                <img src={profile_icon} alt="profile" />
                             </div>

                             <div className='mt-4'>
                                 <h6 className={styles.profile_name}>{profile.hasOwnProperty("firstName") ? profile.firstName: "Nill"}  {profile.hasOwnProperty("lastName") ? profile.lastName: "Nill"}</h6>
                                 <p className={styles.profile_date}>Registered <Moment format="MMMM Do, YYYY">
                                    {profile.createdAt}
                                    </Moment>
                                  </p>
                             </div>
                             
                             <div className={ `mt-4 ${styles.profile_hr}`}></div>
                         </div>
                        
                        <div className='mt-4'>
                            <h6 className={styles.profile_user_title}>User Details</h6>
                            {/* email */}
                            <div className='mt-4' style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <img src={message} alt="message" />
                                </div>
                                <div className={styles.profile_email}>
                                    <span>{profile.email}</span>
                                </div>
                            </div>

                            <div className={ `mt-3 ${styles.profile_hr}`}></div>

                            <div className='mt-3' style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <img src={call} alt="call" />
                                </div>
                                <div className={styles.profile_email}>
                                    <span>{profile.phoneNumber}</span>
                                </div>
                            </div>
                        </div>


                     </div>

                 </div>
            </div>
                

             </div>
        </div>
        </>
    );
}

const mapStateToProps = (state) =>{
    return{
        profile: state.auth.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        savePassword: (values) => dispatch(ChangePassword(values)),
    };
  };
 
 
export default connect(mapStateToProps, mapDispatchToProps)(UsersProfilePage);