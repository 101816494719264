import cogoToast from "cogo-toast";

const initState = {
    category: [],
    subcategory: [],
    amount: 0,
    terms: "",
    minAmount: 0,
    maxAmount: 0,
    tradeamount: 0,
    userminAmount: 0,
    usermaxAmount: 0,
    coins: [],
    coinAmount: 0,
    usdAmount: 0,
    amountToGet: 0
  };
  
  const rateReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Category':
          return {
                ...state,
                category: action.data
            }
        case 'subCategory':
            return{
                ...state,
                subcategory: action.data
            }
        case 'Terms':
            let terms = state.subcategory.find(pro=> pro._id === action.data)
            return{
                ...state,
                terms: terms.termsandconditions
            }
        case 'RateCalculation':
            let amount;
            // first find the object of category selected
            let categoryDetails = state.subcategory.find(pro=> pro._id === action.data.id)

            // Then do some checks with the amount sent for minimum and maximum
            let minAmount = categoryDetails.minimumAmount
            let maxAmount = categoryDetails.maximumAmount

            if(parseFloat(action.data.amount) < parseFloat(minAmount)){
                cogoToast.info(`The minimum amount for this subcategory is $${minAmount}`)
                amount = 0
            }
            else if(parseFloat(action.data.amount) > parseFloat(maxAmount)){
                cogoToast.info(`The maximum amount for this subcategory is $${maxAmount}`)
                amount = 0
            }
            else{

                let rate = categoryDetails.nairarate

            // Do yor calcluation by mulitplyig the amount times * the rate
             amount = parseFloat(rate) * parseFloat(action.data.amount)
            
            }

            return{
                ...state,
                amount: amount,
                minAmount: minAmount,
                maxAmount: maxAmount
            }

        case 'UserRateCalculation':
            let tradeamount;
            // first find the object of category selected
            let usercategoryDetails = state.subcategory.find(pro=> pro._id === action.data.id)

            // Then do some checks with the amount sent for minimum and maximum
            let userminAmount = usercategoryDetails.minimumAmount
            let usermaxAmount = usercategoryDetails.maximumAmount

            if(parseFloat(action.data.amount) < parseFloat(userminAmount)){
                cogoToast.warn(`The minimum amount for this subcategory is $${userminAmount}`)
                tradeamount = 0
            }
            else if(parseFloat(action.data.amount) > parseFloat(usermaxAmount)){
                cogoToast.warn(`The maximum amount for this subcategory is $${usermaxAmount}`)
                tradeamount = 0
            }
            else{

                let rate = usercategoryDetails.nairarate

            // Do yor calcluation by mulitplyig the amount times * the rate
             tradeamount = parseFloat(rate) * parseFloat(action.data.amount)
            
            }

            return{
                ...state,
                tradeamount: tradeamount,
                userminAmount: userminAmount,
                usermaxAmount: usermaxAmount
            }
        case 'clearTradeAmount':
            return{
                ...state,
                tradeamount: 0
            }
        case 'Coins':
            return{
                ...state,
                coins: action.data
            }
        case 'CoinRateCalculation':
            let amt;
            let usdAmount;
            // first find the object of the coin selected
            let coinDetails = state.coins.find((pro) => pro._id === action.data.id);
            // conversion
            let usdRate = coinDetails.usdRateCoin;
            let localCurrencyRate = coinDetails.localCurrencyRate
        
            usdAmount = parseFloat(action.data.amount) * usdRate

             // calcuate the rate for the specific range the usd amount calcuated falls into
             let nairaRate =  localCurrencyRate.find(x =>  usdAmount >= x.minimumUsdValue  && usdAmount <= x.maximumUsdValue)
             if( typeof nairaRate === 'undefined'){
                nairaRate = localCurrencyRate[localCurrencyRate.length - 1]
             }
           
             amt =
             usdAmount *
             nairaRate.ngnRateUsd;

            return{
                ...state,
                amountToGet: parseFloat(amt.toFixed(2)),
                usdAmount: parseFloat(usdAmount.toFixed(2)),
                coinAmount: parseFloat(action.data.amount)
            }
         case "USDRateCalculation":
                let calcAmount;
                let coinAmt;
                // first find the object of the coin selected
                let coinDet = state.coins.find((pro) => pro._id === action.data.id);
                // conversion
                let usdRt = coinDet.usdRateCoin;
                let localCurrencyRt = coinDet.localCurrencyRate
              
          
                coinAmt = parseFloat(action.data.amount) / usdRt
          
         // calcuate the rate for the specific range the usd amount calcuated falls into
            let nairaRt =  localCurrencyRt.find(x => action.data.amount >= x.minimumUsdValue  && action.data.amount <= x.maximumUsdValue)
                if( typeof nairaRt === 'undefined'){
                nairaRt = localCurrencyRt[localCurrencyRt.length - 1]
                }
            
                calcAmount =
                parseFloat(action.data.amount) *
                parseFloat(nairaRt.ngnRateUsd);
        
            return {
                ...state,
                amountToGet: parseFloat(calcAmount.toFixed(2)),
                coinAmount: parseFloat(coinAmt.toFixed(6)),
                usdAmount: parseFloat(action.data.amount)
            };
        case "clearCryptoValues":
            return {
                ...state,
                amountToGet: 0,
                coinAmount: 0,
                usdAmount: 0
         };
      default:
        return state;
    }
  };
  
  export default rateReducer;
  