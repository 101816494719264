
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/ScrollWrapper/ScrollToTop";

// private routes
import UserRoute from "./components/PrivateRoutes/userRoute";


import NotFoundPage from "./pages/404/NotFound";
import ForgotPasswordPage from "./pages/Auth/forgot";
import LoginPage from "./pages/Auth/login";
import RegisterPage from "./pages/Auth/register";
import ResetPasswordPage from "./pages/Auth/resetPassword";
import VerifyInfoPage from "./pages/Auth/verifyInfo";
import VerifySuccessPage from "./pages/Auth/verifySuccess";
import ContactPage from "./pages/Contact/contact";
import LandingPageLayout from "./pages/LandingPage/home";
import PrivacyPage from "./pages/Privacy/privacy";
import TermsPage from "./pages/Terms/Terms";
import UsersAccountDetailsPage from "./pages/Users/AccountDetails/account";
import UsersDashboard from "./pages/Users/Dashboard/dashboard";
import UserNotificationsPage from "./pages/Users/Notifications/notifications";
import UsersProfilePage from "./pages/Users/Profile/profile";
import ChangePinPage from "./pages/Users/Security/ChangePin";
import UsersManagePinPage from "./pages/Users/Security/ManagePin";
import ResetPinPage from "./pages/Users/Security/ResetPin";
import ResetPinTokenPage from "./pages/Users/Security/ResetPinToken";
import SetNewPinPage from "./pages/Users/Security/SetNewPin";
import CardTradePage from "./pages/Users/StartTrade/CardTrade";
import CryptoSuccessPage from "./pages/Users/StartTrade/CryptoSuccess";
import CryptoTradePage from "./pages/Users/StartTrade/CryptoTrade";
import StartTradePage from "./pages/Users/StartTrade/StartTrade";
import CryptoDetails from "./pages/Users/Trades/CryptoDetails";
import GiftcardDetails from "./pages/Users/Trades/GiftcardDetails";
import UserTrade from "./pages/Users/Trades/trades";
import TransactionDetails from "./pages/Users/Wallets/TransactionDetails";
import UsersWallet from "./pages/Users/Wallets/wallets";
import WithdrawPage from "./pages/Users/Wallets/withdraw";

function App() {
  return (
    <>
     <Router>
     <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<LandingPageLayout />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/verifyaccount" element={<VerifyInfoPage />} />
          <Route path="/verifyemail/:code" element={<VerifySuccessPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />
          <Route path="/terms" element={<TermsPage/>} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact-us" element={<ContactPage />} />

          <Route path="/dashboard" element={
            <UserRoute>
                <UsersDashboard />
            </UserRoute>        
          } />
          <Route path="/start-trade" element={
              <UserRoute>
                <StartTradePage />
              </UserRoute>
              } />
          <Route path="/start-trade/crypto" element={
              <UserRoute>
                <CryptoTradePage />
              </UserRoute>
              } />
          <Route path="/start-trade/crypto/complete" element={
                <UserRoute>
                  <CryptoSuccessPage/>
                </UserRoute>
            }/>
          <Route path="/start-trade/giftcard" element={
            <UserRoute>
                <CardTradePage />
              </UserRoute>
          } />
          <Route path="/trades" element={
              <UserRoute>
                <UserTrade />
              </UserRoute>
            } />
          <Route path="/trade/crypto/:id" element={
              <UserRoute>
                <CryptoDetails/>
              </UserRoute>
          } />
          <Route path="/trade/giftcard/:id" element={
              <UserRoute>
              <GiftcardDetails/>
            </UserRoute>
          } />
          <Route path="/my-wallet" element={
            <UserRoute>
              <UsersWallet />
            </UserRoute>
          } />
          <Route path="/my-wallet/withdraw" element={
            <UserRoute>
              <WithdrawPage />
              </UserRoute>
            } />
          <Route path="/my-wallet/details/:id" element={
            <UserRoute>
            <TransactionDetails />
            </UserRoute>
          } />
          <Route path="/security/manage-pin" element={
              <UserRoute>
              <UsersManagePinPage />
              </UserRoute>
          } />
          <Route path="/security/change-pin" element={
            <UserRoute>
              <ChangePinPage />
            </UserRoute>
          } />
          <Route path="/security/reset-pin" element={
            <UserRoute>
          <ResetPinPage />
          </UserRoute>
          } />
          <Route path="/security/reset-pin/set" element={
            <UserRoute>
              <SetNewPinPage />
            </UserRoute>
          } />
          <Route path="/security/reset-pin/token" element={
              <UserRoute>
              <ResetPinTokenPage />
              </UserRoute>
              } />
          <Route path="/notifications" element={
                <UserRoute>
                <UserNotificationsPage />
                </UserRoute>
                } />
          <Route path="/account-details" element={
              <UserRoute>
              <UsersAccountDetailsPage />
              </UserRoute>
              } />
          <Route path="/profile" element={
            <UserRoute>
              <UsersProfilePage />
            </UserRoute>
          } />
          <Route path="*" element={<NotFoundPage />}/>
        </Routes>
       </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
