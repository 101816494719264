import React, {useRef} from 'react';
import Navbar from '../../components/LandingComponents/Navbar';
import styles from './contact.module.css'
import phone from '../../assets/icons/phone-call.svg'
import { Form, Formik } from "formik";
import { contactValidator } from "../../validationSchema/validator";
import Footer from '../../components/LandingComponents/Footer';
import { connect } from 'react-redux';
import { contactSupport } from '../../store/actions/auth';

const ContactPage = ({handleContact}) => {

    const ref = useRef();

    const handleSubmit = async (values) => {
        console.log(values)
        await handleContact(values)

         // reset the form
        setTimeout(() => {
            ref.current.reset();
        }, 3000);
    };

    return ( 
        <>
        <Navbar />
        <div className='contain'>

            <div className={`mt-5 ${styles.about_heading}`}>
                  <h4>Contact us</h4>
             </div>

             <div className='row mb-5'>
                 <div className='col-lg-7'>

                     <div className='mt-4'>
                         <p className={styles.title}>
                         We are never far away. If you ever need help or inquiries, 
                         <br/>
                         We are just one click away. All details about us are contained on the website or on the app. An experience tailored just for you.   
                         </p>
                     </div>

                    <div className={styles.contact_form}>
                     <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={contactValidator}
                    initialValues={{ email: "", firstname: "", lastname: "", message: "", }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form ref={ref} onSubmit={handleSubmit}>
                        {/* form section */}
                        <div className="mt-3 mt-md-4">

                             {/* first Name */}
                             <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="firstname">First Name</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="text"
                                    placeholder="Firstname"
                                    id="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstname}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.firstname && errors.firstname}
                            </small>
                            </div>

                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="lastname">Last Name</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="text"
                                    placeholder="Lastname"
                                    id="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastname}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.lastname && errors.lastname}
                            </small>
                            </div>

                            {/* email address */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="email">Email</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="email"
                                    placeholder="Email Address"
                                    id="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.email && errors.email}
                            </small>
                            </div>

                            {/* phone number */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="message">Your Message</label>
                            <div style={{ position: "relative" }}>
                                <textarea
                                     rows="6"
                                    className={`${styles.input_style}`}
                                    type="text"
                                    placeholder="Drop a message"
                                    id="message"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.message}
                                    style={{resize: 'none'}}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.message && errors.message}
                            </small>
                            </div>

                            <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn ${styles.btn_contact}`}
                            >
                                Send Message
                            </button>
                            </div>

                        </div>
                        </Form>
                    )}
                    </Formik>
                    </div>

                 </div>

                 <div className='col-lg-5'>

                     <div className='mt-5 mt-lg-4'>
                         <h6 className={styles.title_two}>Offices</h6>
                     </div>

                     {/* details */}
                     <div className='mt-3'>
                         <p className={styles.office_location}>
                           Lagos,
                         </p>
                         <p className={styles.office_location}>
                             Lekki Chveron
                         </p>
                     </div>


                     <div className='mt-5'>
                         <h6 className={styles.title_two}>For Quick Inquiries</h6>
                     </div>

                     <div className={`mt-4 ${styles.phone_div}`}>
                         <img src={phone} alt="phone" />
                         <div>
                             <p className={styles.phone_val}>+234 810 099 3483</p>
                         </div>
                     </div>

                    

                 </div>

             </div>

        </div>

        <Footer />
        </>
     );
}


const mapStateToProps = (state) => {
    return {

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        handleContact: (val) => dispatch(contactSupport(val))
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);