import React, {useEffect, useState} from 'react';
import styles from './landing.module.css'
import {Form, Formik} from 'formik'
import {rateValidator} from '../../validationSchema/validator'
import Nigeria from '../../assets/images/nigerialogo.svg'
import trendup from '../../assets/icons/trending-up.svg'
import trenddown from '../../assets/icons/trending-down.svg'
import { connect } from 'react-redux';
import { getRateCategory, getRateSubCategory, getRateValue } from '../../store/actions/rate';
import axios from 'axios';


const RateSection = ({fetchCategory, category, fetchSubCategory, subcategory, amount, calcRate}) => {

    const [marketprice, setMarketPrice] = useState([])

    useEffect(()=>{
       const fetchMarketPrice = async () =>{
            const resp = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false')
            setMarketPrice(resp.data)
        }
        fetchMarketPrice()
    },[])

  useEffect(() =>{
        fetchCategory()
  }, [fetchCategory])

  const handleSubCategory = (val) =>{
    fetchSubCategory(val)
   }

    const handleSubmit = (values, setSubmitting,)  =>{

        var amount = values.amount;
        var categoryId =  values.category

        calcRate(amount, categoryId)

        setTimeout(() => {
            setSubmitting(false);
        }, 1000);
    }

    const handleCapitalize = (val) =>{
        return val.toUpperCase()
    }

    const numberWithCommasFixed = (x) => { 
        return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }
      

    return ( 
        <>
        <div className={styles.coins_div}>
            <div className='contain'>
            <div className={styles.coin_layout}>

                 <div className={styles.crypto_snapshots}>
                    {(
                        marketprice.length > 0 ? marketprice.map((item)=>(
                        <div key={item.id} className={styles.crypto_snapshot}>
                                <div className={styles.crypo_icon}>
                                    <img src={item.image} alt="coin" className='img-fluid'/>
                                </div>
                                <div className='ml-2'>
                                    <p className={styles.coin_text}>{handleCapitalize(item.symbol)}/USD <span><img
                                         src={Math.sign(item.price_change_percentage_24h) === 1 ? trendup : trenddown}   
                                         alt="trend" 
                                     /></span> {numberWithCommasFixed(item.price_change_percentage_24h)}%</p>
                                    <p 
                                    className={
                                        Math.sign(item.price_change_percentage_24h) === 1 ? styles.trendup_text : styles.trenddown_text  
                                    }
                                    >${numberWithCommas(item.current_price)}</p>
                                </div>
                            </div>
                            ))
                            :
                        <>
                        </>
                    )} 
                </div>

            </div>
            </div>


        </div>
        <div className={styles.rates_div} id="rate">

            <div className='contain'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <div className={styles.rate_first_section}>
                            <h3 className={styles.rate_title}>Gift card rate calculator</h3>
                            <p className={styles.rate_subtitle}>We are ready to provide the best price always</p>
                        </div>

                    </div>
                    <div className='col-lg-7'>

                    <Formik
                        onSubmit={(values, {setSubmitting}) =>
                            handleSubmit(values, setSubmitting)
                            }
                        validationSchema={rateValidator}
                        enableReinitialize={true}
                        initialValues={{giftname: "", category: "", amount: ""}}
                            >
                                {({
                            handleChange,
                            isSubmitting,
                            handleSubmit,
                            handleBlur,
                            handleReset,
                            setFieldValue,
                            values,
                            touched,
                            errors
                        })=>(
                            <Form onSubmit={handleSubmit}>
                                <div className='row mt-3 mt-lg-0'>
                                    <div className='col-lg-6'>
                                        <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor="giftname">Gift Card Name</label>
                                                <select
                                                    defaultValue=""
                                                    name="giftname"
                                                    values={values.giftname}
                                                    onChange={(e) => {
                                                    handleChange(e, setFieldValue("category", ""))
                                                    handleSubCategory(e.currentTarget.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    className={`${styles.input_style}`}
                                                    id="giftname">
                                                <option value="" disabled>Select</option>        
                                                 {category.map((opt, index) => {
                                                    return <option key={index} value={opt._id}>{opt.categoryname}</option>
                                                 })}       
                                            </select>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.giftname && errors.giftname}
                                            </small>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className={`mb-3 ${styles.input_container}`}>
                                                <label htmlFor="category">Gift Card Category</label>
                                                    <select
                                                        defaultValue=""
                                                        name="category"
                                                        values={values.category}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`${styles.input_style}`}
                                                        id="category">
                                                    <option selected={values.category === ""} value="" disabled>Select</option>   
                                                    {subcategory.map((opt, index) => {
                                                        return <option key={index} value={opt._id}>{opt.subcategoryname}</option>
                                                    })}     
                                                </select>
                                                <small style={{ color: "#dc3545" }}>
                                                    {touched.category && errors.category}
                                                </small>
                                            </div>
                                    </div>

                                    <div className='col-lg-6'>
                                      <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor="amount">Amount</label>
                                            <div>
                                                <input
                                                    className={`${styles.input_style}`}
                                                    type="text"
                                                    placeholder="Amount in USD"
                                                    id="amount"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.amount}
                                                />
                                            </div>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.amount && errors.amount}
                                            </small>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                      <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="amount">You Get</label>
                                              <div className={styles.amount_style}>
                                                    <div className={styles.amount_div}>
                                                        <div>
                                                            <img src={Nigeria} style={{width: '25px', height:"25px"}} alt="nigeria" />
                                                        </div>
                                                        <div className="ml-2">
                                                            <p className="mb-0" style={{color: '#2C3A50', fontWeight: 'bold'}}>NGN</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    className={`${styles.amount_input}`}
                                                    type="text"
                                                    placeholder="0.00"
                                                    id="amount"
                                                    disabled
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,",")}
                                             />
                                           
                                        </div>
                                    </div>

                                </div>

                                <div className="text-center mt-3">
                                    <button 
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`btn ${styles.btn_calc}`}>Calculate</button>
                                    </div>

                                </Form>
                        )}

                        </Formik>
                        
                    </div>
                </div>

            </div>

        </div>
        </>
     );
}

const mapStateToProps = (state) =>{
    return{
        category: state.rate.category,
        subcategory: state.rate.subcategory,
        amount: state.rate.amount
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetchCategory: () => dispatch(getRateCategory()),
        fetchSubCategory: (id) => dispatch(getRateSubCategory(id)),
        calcRate: (amount, id) => dispatch(getRateValue(amount, id))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RateSection);