import React,{useState, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import icon1 from '../../../assets/icons/icon-1.svg'
import rocket from '../../../assets/icons/rocket-white.svg'
import styles from './wallet.module.css'
import { Form, Formik } from "formik";
import { withdrawValidator } from "../../../validationSchema/validator";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearWithdrawal, WithdrawFunds } from '../../../store/actions/transactions';
import { getWalletBalance } from '../../../store/actions/dashboard';


const WithdrawPage = ({accountDetails, handleWithdraw, withdrawsuccess, clearWithdrawSuccess, balance, fetchBalance}) => {

    const [bankInfo, setBankInfo] = useState({});

    const navigate = useNavigate()

    useEffect(()=>{
        fetchBalance()
      },[fetchBalance])
    

    const handleSubmit = async (values) => {
        console.log(values);
        const creds = {
            ...values,
            accountName: bankInfo.accountName,
            accountNumber: bankInfo.accountNumber,
            bankCode: bankInfo.bankCode,
            bankName: bankInfo.bankName,
            amount: parseFloat(values.amount),
            pin: parseInt(values.pin),
        }
        console.log(creds)
        await handleWithdraw(creds)
    };

    const handleType = (id) => {
      var val = accountDetails.find((pro) => pro.id === id);
        setBankInfo(val);
    };

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
        }

    useEffect(()=>{
        if(withdrawsuccess){
            setTimeout(()=>{
                navigate('/my-wallet')
                clearWithdrawSuccess()
            },2000)    
        }
    },[withdrawsuccess,navigate, clearWithdrawSuccess])

   

        
    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Withdraw</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            
            <div className='row mt-5 mb-5'>

                <div className='col-lg-8'>

                {
                    accountDetails && accountDetails.length > 0  ?
                    <div className={styles.withdraw_card}>

                        <div>

                        <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={withdrawValidator}
                    initialValues={{ accountType: "", amount: "", narration: "", pin: "", }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                        {/* form section */}
                        <div className="mt-md-2">
                            {/* AMOUNT*/}
                            <div className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor="amount">Withdrawal Amount</label>
                                <div style={{ position: "relative" }}>
                                    <input
                                        className={`${styles.withdraw_input_style}`}
                                        type="text"
                                        placeholder="Enter an amount"
                                        id="amount"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.amount}
                                    />
                                </div>
                                <small style={{ color: "#dc3545" }}>
                                    {touched.amount && errors.amount}
                                </small>
                            </div>

                            <div className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor="accountType">Select account to withdraw into</label>
                                <select
                                    name="accountType"
                                    values={values.accountType}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleType(e.currentTarget.value);
                                    }}
                                    defaultValue=""
                                    onBlur={handleBlur}
                                    className={`${styles.withdraw_input_style}`}
                                    id="accountType"
                                    >
                                    <option value="" disabled>
                                             --Select--
                                    </option>
                                    {accountDetails.map((opt, index) => {
                                        return (
                                        <option key={index} value={opt.id}>
                                            {opt.accountNumber}{" "}
                                            -{" "}
                                            {opt.accountName}
                                        </option>
                                        );
                                    })}
                                 </select>
                                <small style={{ color: "#dc3545" }}>
                                    {touched.accountType && errors.accountType}
                                </small>
                            </div>

                            <div className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor="bank">Bank</label>
                                <div style={{ position: "relative" }}>
                                    <input
                                        className={`${styles.withdraw_input_style}`}
                                        type="text"
                                        placeholder="Bank Name"
                                        id="bank"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled
                                        value={
                                            bankInfo && bankInfo.bankName
                                              ? bankInfo.bankName
                                              : ""
                                          }
                                    />
                                </div>
                               
                            </div>

                            <div className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor="narration">Description</label>
                                <div style={{ position: "relative" }}>
                                    <textarea
                                        className={`${styles.withdraw_input_style}`}
                                        type="text"
                                        placeholder="Enter a description"
                                        id="narration"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.narration}
                                        rows="4 "
                                        style={{resize: 'none'}}
                                    />
                                </div>
                                <small style={{ color: "#dc3545" }}>
                                    {touched.narration && errors.narration}
                                </small>
                            </div>

                            <div className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor="pin">Transaction Pin</label>
                                <div style={{ position: "relative" }}>
                                    <input
                                        className={`${styles.withdraw_input_style}`}
                                        type="password"
                                        placeholder="Enter your Transaction Pin"
                                        id="pin"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.pin}
                                    />
                                </div>
                                <small style={{ color: "#dc3545" }}>
                                    {touched.pin && errors.pin}
                                </small>
                            </div>

                            <div className="mt-4 text-center">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn ${styles.btn_withdraw}`}
                            >
                                Withdraw
                            </button>
                            </div>
                        </div>
                        </Form>
                    )}
                    </Formik>

                        </div>
                        
                    </div>

                    :
                    <div className={`mt-4 ${styles.nowithdraw_card}`}>
                        
                        <div className='text-center'>
                            <h2 className={styles.no_account_title}>No account added yet</h2>
                            <p className={styles.no_account_subtitle}>Kindly add an account to allow withdrawal of funds</p>
                        </div>

                        <div className="mt-4 text-center">
                            <Link
                                to="/account-details"
                                className={`btn ${styles.btn_withdraw}`}
                            >
                                Add Account Now
                            </Link>
                        </div>
                                      
                    </div>
                  }
                </div>
                    


                <div className='col-lg-4 mt-md-0 mt-3 d-none d-md-block'>

                <div className={styles.wallet_card_div}>
                             <div className={styles.wallet_value_div}>
                                     <div>
                                        <img src={icon1} alt="icon"/>
                                    </div>
                                    <div className='ml-3 ml-md-3'>
                                        <h6 className={styles.wallet_title}>Wallet Balance</h6>
                                        <h5 className={styles.wallet_subtitle}> NGN {numberWithCommas(balance)}</h5>
                                    </div>
                            </div>
                        </div>

                        <div className={`mt-3 ${styles.trade_card_div}`}>
                            <div className={styles.trade_card_title_div}>
                                <div>
                                    <img src={rocket} alt="rocket" className='img-fluid' />
                                </div>
                                <div className='ml-3' style={{flex: 1}}>
                                    <h6 className={styles.trade_title}>Start a trade</h6>
                                    <p className={styles.trade_subtitle}>Trade your gift cards and crypto currencies with ease at mouth watering rates today.</p>
                                </div>
                            </div>

                            <div className='mt-2'>
                                <Link to="/start-trade" className={`btn ${styles.trade_btn}`}>Trade Now</Link>
                            </div>
                    </div>


                </div>
            </div>

            </div>
        </div>
        </>
     );
}



const mapStateToProps = (state) =>{
    return{
        accountDetails: state.auth.accountDetails,
        withdrawsuccess: state.transaction.withdrawsuccess,
        balance: state.dashboard.WalletBalance
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleWithdraw: (values) => dispatch(WithdrawFunds(values)),
        clearWithdrawSuccess: () => dispatch(clearWithdrawal()),
        fetchBalance: () => dispatch(getWalletBalance()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawPage);