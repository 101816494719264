import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/LandingComponents/Footer';
import Navbar from '../../components/LandingComponents/Navbar';
import styles from './terms.module.css'


const TermsPage = () => {
    return ( 
        <>
        <Navbar/>
        <div className='contain'>
            <div className={`mt-5 ${styles.terms_heading}`}>
                <h4>Terms and Conditions</h4>
            </div>
            <div className={`mt-4 mb-5 ${styles.terms_content}`}>
                <div>
                    <p>Your access to and use of this Exchange Platform (Website and Services) implies that you, a User has read and accepted these Terms of Use. In case your views vary with any part of these Terms of Use, we advise that you do not access or create a profile on our Platform.</p>
                </div>

                <div className='mt-5'>
                    <h6>ABOUT US</h6>
                    <p className='mt-3'>
                         Ikinghub Exchange (subsequently referred to as “We”, “Us”, or “Our”) offers users a platform and avenue to trade Cryptocurrency and engage in Gift Card exchange at your convenience, from any place and at any time.
                        We assist our Users in creating a profile for their transactions and
                        exposing them to a community of Cryptocurrency and Gift Cards enthusiasts thereby facilitating
                        healthy communications and profitable transactions.
                    </p>
                    <p>
                    This Terms of Use is an Agreement between you and Ikinghub Exchange. It provides in detail, our commitments, responsibilities, and obligations to you. It goes further to state in clear terms, the risks associated with the use our Services based on availability on this platform and the reasons why you must consider such risks carefully as you will be required to abide by the provisions of this Agreement through your use of this Website or any of our Services.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>PRIVACY POLICY</h6>
                    <p className='mt-3'>
                         Ikinghub Exchange is committed to managing your Personal Information in accordance with global industry best practises and the provisions of the Nigeria Information Technology Development Agency (NITDA) Act. You can read our <span>
                        <Link to="/privacy">Privacy Policy</Link></span>  to understand how we use your information and the mechanisms we have in place to protect your information.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>REGISTRATION</h6>
                    <p className='mt-3'>
                    To use Our platform, you have to create a User profile by registering. To register, you will provide us with certain information that personally and uniquely identifies you such as your firstname, lastname email address, and your phone number. We may also seek to verify your information (by ourselves or through third parties) after which we will approve the creation of your User profile as long as it is not perceived as risky to our business. You give us permission to do all these.
                    You have the right to refuse to authorise the use and/or disclosure of your Personal Information. However, if you choose to
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>EDITORIAL CONTENT</h6>
                    <p className='mt-3'>
                    We strive to make our Services available to our registered Users and other members of the digital
                        community who are finance and Cryptocurrency enthusiasts and dealers in Gift Cards.
                    </p>
                    <p>
                    However, take notice that, that the timeliness and accuracy of any or all the content that will be
                    periodically available on this platform is not guaranteed. Neither the authors, the editorial
                    personnel, nor any other party who has been involved in the preparation or publication of the
                    contents can assure you that such contents are in every respect accurate or complete. They are
                    therefore absolved of any responsibility and liability that may arise from any errors or omissions
                    or the results obtained from the use of the content. You are admonished to independently confirm
                    the content of the service contained on this platform with other reliable sources and to seek expert
                    opinion from trusted personnel.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>AGE RESTRICTION</h6>
                    <p className='mt-3'>
                    Our Website and Services are not designed or made available for persons under 18. It is our understanding that you are independently responsible for conforming with all applicable laws related to your use of our Website and Services.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>CHANGE OF INFORMATION</h6>
                    <p className='mt-3'>
                    If you alter, modify or change any information you provided to us at the point of registration in a bid to create a profile, you agree to have us confirm such new details as you have provided to us and to ascertain that it is not associated or linked to the details of any other registered User on our platform. We may therefore be unable to respond to you, if you contact us from an address, telephone number or email account which you have updated your profile to reflect but that has not been ascertained by us.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>REPRESENTATIONS AND WARRANTIES</h6>
                    <p className='mt-3'>
                    You represent and warrant to Ikinghub Exchange that:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        You have full power, authority and physical and mental capacity to enter into, execute and perform this Agreement;
                        </li>
                        <li className='mb-2 ml-3'>
                        You will observe the contents of this Agreement and all applicable local and international regulations;
                        </li>
                        <li className='mb-2 ml-3'>
                        Any information you provide to us upon registration and subsequently, is and will be true, accurate and complete;
                        </li>
                        <li className='mb-2 ml-3'>
                        You will keep updating your information to reflect current realities; and
                        </li>
                        <li className='mb-2 ml-3'>
                        You accept and agree to these Terms
                        </li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h6>ACCOUNT SECURITY</h6>
                    <p className='mt-3'>
                    You agree to not allow anyone else to have access to or use your password details and to comply with all reasonable instructions we may issue regarding account access and security. In the event you share your password details, we will not be liable to you for losses or damages. You will also take all reasonable steps to protect the security of the personal electronic device through which you access our Services (including, without limitation, using PIN and/or password protected personally configured device functionality to access our services and not sharing your device with other people). You are responsible for securely managing your details and most especially the password to your user profile and to contact our  <span><Link to="/contact">helpdesk</Link></span>  you become aware of any unauthorised access to your profile.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>LINKS TO OR CONNECTIONS WITH THIRD PARTY SITES OR APPLICATIONS</h6>
                    <p className='mt-3'>
                        Our services to you or communications and interactions with you may contain third party content or links to third party sites, applications or services. We do not control, maintain, or approve these third party contents, links or services and we are not liable for any any damages, losses, failures, or problems caused by, related to, or arising from your interactions or dealings with these content.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>RESPONSIBILITIES</h6>
                    <p className='mt-3'>
                    By agreeing to these terms of use, you agree to the following responsibilities:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        The usual and customary charges for any transaction being carried out on our platform or services being rendered to you by us will apply and will be entirely your responsibility;
                        </li>
                        <li className='mb-2 ml-3'>
                        You are responsible for the use of your profile, including use by others to whom you may have given your security details;
                        </li>
                        <li className='mb-2 ml-3'>
                        You agree that you shall not copy, modify, adapt, amend, or translate any portion of this site, its content or materials and/or the services; and
                        </li>
                        <li className='mb-2 ml-3'>
                        You agree that you shall not create a User profile through any false or fraudulent means;
                        </li>
                    </ul>
                </div>


                <div className='mt-5'>
                    <h6>UNACCEPTABLE USE</h6>
                    <p className='mt-3'>
                    You shall not permit any person using your device or user profile to:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        use this platform in a way that causes or could cause damage or restrict the availability or accessibility of the platform;
                        </li>
                        <li className='mb-2 ml-3'>
                        store, copy, transmit, publish or distribute any computer virus, spyware, trojan horse, worm, keystroke logger or other malicious software via this platform;
                        </li>
                        <li className='mb-2 ml-3'>
                        conduct any fraudulent or illegal activities via the platform; and
                        </li>
                        <li className='mb-2 ml-3'>
                        You hereby understand and agree that if fraudulent activity is associated with the operation of your Account, we have the right to apply restrictions to your Account and report to the appropriate law enforcement agencies.
                        </li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h6>INTELLECTUAL PROPERTY</h6>
                    <p className='mt-3'>
                    All content and materials, including but not limited to images, text, visual interfaces, information, data, and computer code, provided by us through our Services and all related intellectual property rights are the property of Ikinghub Exchange and/or its third-party licensors. Our content is protected by Nigerian and international intellectual property laws and treaties. You are permitted to use our content only for our Services. You may not copy, modify, sell, reproduce, distribute, republish, display, post or transmit in the content on this platform in any form without our express prior written consent. Nothing in the Agreement or our Services grants you, by implication, estoppel, or otherwise, any licence or right to use our content except as expressly stated in the Agreement.
                    </p>
                </div>


                <div className='mt-5'>
                    <h6>LIMITATION OF LIABILITY</h6>
                    <p className='mt-3'>
                    Ikinghub Exchange will not be liable for any direct, indirect, incidental or consequential damage occasioned by failure of internet network, security, telecommunications, virus, spyware, loss or theft of data that does not meet our systems requirements or that was not a result of our acts or omissions.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>INDEMNITY</h6>
                    <p className='mt-3'>
                    You agree to defend, indemnify, and hold Ikinghub Exchange, its officers, directors, employees, agents, licensors, and suppliers, harmless from and against any claims, damages, liabilities or settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of this Agreement.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>PAYMENT TERMS AND PROCESSING FEES</h6>
                    <p className='mt-3'>
                    Payments through credit card and debit card are collected online without any hassle. The patient
                    is asked to enter the card number, the expiry date of the card, and its security number. No data
                    is saved, and no additional fees except that of the transaction are charged. The money is deducted
                    once the user clicks on “Make Payment” and the transactions are 100% secure.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>APPLICABLE LAW</h6>
                    <p className='mt-3'>
                    These Terms of Use shall be interpreted and governed by the laws currently in force in the Federal Republic of Nigeria and any part of this Agreement which is rendered invalid by any such law shall be severed from the remaining part of this Agreement.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>TERMINATION</h6>
                    <p className='mt-3'>
                    You may terminate this Agreement by deactivating and deleting your User profile.
                    </p>
                    <p>
                    We may suspend your Account and your access to our Services and any funds, or terminate this Agreement, if:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        you do not comply with any of the provisions of this Agreement;
                        </li>
                        <li className='mb-2 ml-3'>
                        we are required to do so by a law or judicial pronouncement;
                        </li>
                        <li className='mb-2 ml-3'>
                        we are directed by a ministry or agency of government; or
                        </li>
                        <li className='mb-2 ml-3'>
                        where a suspicious or fraudulent transaction occurs.
                        </li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h6>SEVERABILITY</h6>
                    <p className='mt-3'>
                    If any portion of this Terms of Use is held by any court or tribunal to be invalid or unenforceable, either in whole or in part, then that part shall be severed from this Terms of Use and shall not affect the validity or enforceability of any other part in this Terms of Use.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>UPDATES, MODIFICATIONS AND AMENDMENTS</h6>
                    <p className='mt-3'>
We may need to update, modify or amend our system, this Terms of Use, tools, utilities, or general updates to align with contemporary realities and technological evolution. We reserve the right to make changes to this Terms of Use at any time by giving notice to Users on this platform.
                    </p>
                    <p>
                    We advise that you check this page often, referring to the date of the last modification on the page. If a User objects to any of the changes to the Terms of Use, the User must cease using our website and/or services immediately.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>COMPLAINTS</h6>
                    <p className='mt-3'>
                    If you have any complaints or reservations about us or any of the Services we provide, you may contact us via our <span><Link to="/contact">Contact Page</Link></span>
                    </p>
                </div>



            </div>
        </div>

        <Footer/>
        </>
     );
}
 
export default TermsPage;