import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/LandingComponents/Footer';
import Navbar from '../../components/LandingComponents/Navbar';
import styles from './privacy.module.css'

const PrivacyPage = () => {
    return ( 
        <>
        <Navbar/>
        <div className='contain'>
             <div className={`mt-5 ${styles.terms_heading}`}>
                <h4>Privacy Policy</h4>
            </div>
            <div className={`mt-4 mb-5 ${styles.terms_content}`}>
                <div>
                    <p>
                    At Ikinghub Exchange, we pledge to safeguard your Personal Data even as we acknowledge your right to privacy. This Privacy Policy seeks to bring to your attention our services, procedures, rules of engagement and the ways through which your information about your person may be collected, used, stored and disclosed when you use our <span>
                        <Link to="/">website</Link></span> to facilitate your Cryptocurrency transactions and Gift Cards exchanges, and other online services created or hosted by us. Kindly note that to enjoy services rendered by Ikinghub Exchange, you may be required to provide certain details. Our online services also make use of cookies and similar technologies in order to give you the best experience.
                       </p>
                </div>

                <div className='mt-5'>
                    <h6>SCOPE OF PRIVACY POLICY</h6>
                    <p className='mt-3'>
                    Ikinghub Exchange Privacy Policy addresses key issues like the collection and use of your personal Data, use of our Cookies, sharing and disclosing your information to third parties, collection of anonymous information, securing your personal Data and protection of your privacy rights, retention of your personal Data in our systems, periodic changes that may be made to this Privacy Policy and how your feedbacks will be of great value to us.
                    </p>
                    <p>
                    We have created this Privacy Policy to explain how we collect, use, disclose and protect your Personal Data, including any other information you may be required to provide, or that you may voluntarily provide during your time on our website. </p>
                    <p>By accepting this Privacy Policy, you have given Ikinghub Exchange, consent to deal with your Personal Data by saving, processing and using such Personal Data to the extent as allowed by law, when you provide us with same or by clicking on the "accept" button.
                    </p>
                    <p>
                        We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy, and in some cases, we may provide you with additional notice (such as by adding a statement to the homepage of this website and or by sending you an email notification)
                    </p>
                    <p>
                    We encourage you to review the Privacy Policy whenever you interact with us to stay informed about our information practices and the ways you can help protect your privacy while still offering the best services.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>COLLECTION OF INFORMATION</h6>
                    <p className='mt-3'>
                    We guarantee that any Personal Data we receive from you will be managed and processed in line with the applicable privacy and data protection laws including the Nigeria Data Protection Regulation (NDPR). We look forward to receiving and storing personal information you provide directly to us. When you sign up as a new user on our website, we collect personal information, such as name, phone number and email address to provide you with our services. This information is used to set up your user profile on our system and helps us in making our services available to you in a most seamless manner. The information we request directly from our customers and users include: name, email address, username, job designation, phone number and transactional information (debit or credit card information) as well as other information which they choose to make available to us or upload to our system.
                    </p>
                    <p> We may collect, process and use the following information about you:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        Information that you provide to us, for example when you fill out a contact or web form, or if you register to receive alerts or updates.
                        </li>
                        <li className='mb-2 ml-3'>
                        When you provide your Personal Data while signing up for a service or transacting business.
                        </li>
                        <li className='mb-2 ml-3'>
                        Personal Data that we obtain or learn, such as information about the browser or device you use to access this site, how you use this site and the pages you visit, including traffic and location data.
                        </li>
                        <li className='mb-2 ml-3'>
                        When you contact our customer support whether by phone, email, or chat.
                        </li>
                        <li className='mb-2 ml-3'>
                        We may ask you for information if you experience problems when using this site. We may also ask you to complete surveys for research purposes, although you don’t have to respond to these.
                        </li>
                        <li className='mb-2 ml-3'>
                        [Personal Data we receive from other sources:  We will notify you when we receive Personal
Commented [JA3]: Kindly insert names of third parties if applicable
 Data about you from them and the purposes for which we intend to use that Personal Data]
                        </li>
                    </ul>
                   
                </div>


                <div className='mt-5'>
                    <h6>USE OF PERSONAL DATA</h6>
                    <p> 
We may use your Personal Data, including non-public Personal Data as follows:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        To create your Ikinghub Exchange user’s transactions profile, deliver the services you request, process transactions and send you related information, including confirmations.
                        </li>
                        <li className='mb-2 ml-3'>
                        To administer our website and for internal operations, including testing, data analysis, trouble shooting, statistical and survey purposes.
                        </li>
                        <li className='mb-2 ml-3'>
                        Process and complete transaction and send related messages
                        </li>
                        <li className='mb-2 ml-3'>
                        In maintaining and improving on our service delivery to you
                        </li>
                        <li className='mb-2 ml-3'>
                        In verifying your identity and preventing fraud.
                        </li>
                        <li className='mb-2 ml-3'>
                        To send you technical notices, updates, security alerts and support and administrative messages.
                        </li>
                        <li className='mb-2 ml-3'>
                        In responding to your comments, questions and requests and provide customer service.
                        </li>
                        <li className='mb-2 ml-3'>
                        To communicate with you about products, services, offers, promotions and rewards offered by Ikinghub Exchange and provide news and information we think will be of interest to you.
                        </li>
                        <li className='mb-2 ml-3'>
                        In monitoring and analysing trends and activities in connection with our services.
                        </li>
                        <li className='mb-2 ml-3'>
                        To personalize and improve the services we render and provide content or features
that match user profiles or interests.
                        </li>
                        <li className='mb-2 ml-3'>
                        Carry out any other purpose for which the Personal Data was collected.
                        </li>
                    </ul>

                    <p> 
                    We only use your personal information within the contexts mentioned above and based on the contact we have in place with you and for legitimate interest for security purposes. By accessing or using the Services or otherwise providing information to us, you consent to the legal regime governing processing and transfer of information in Nigeria, and to Nigeria from any other country.
                    </p>
                   
                </div>

                <div className='mt-5'>
                    <h6>DISCLOSURE OF INFORMATION</h6>
                    <p className='mt-3'>
                    We do not rent or sell out personal information to any person or organization. Ikinghub Exchange will only disclose Personal Data with other companies or individuals in the following limited circumstances:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        To affiliated companies or other trusted businesses or persons for the purpose of processing Personal Data on our behalf. We require that these parties agree to process such Personal Data based on our instructions and in compliance with the Nigeria Data Protection Regulation (NDPR) and any other appropriate confidentiality and security measures. When they no longer need your Personal Data to fulfil this service, they will dispose of the details in line with the NDPR unless they are under a legal obligation to retain such information.
                        </li>
                        <li className='mb-2 ml-3'>
                        To third parties such as consultants and email service providers that perform marketing services on our behalf.
                        </li>
                        <li className='mb-2 ml-3'>
                        To other non-affiliated companies for our everyday business purposes, such as to process transactions, maintain accounts and offer feedbacks.
                        </li>
                    </ul>

                    <p>
                    In situations where Ikinghub Exchange does not have a lawful basis for disclosure of your Personal Data, we will obtain consent from you before sharing your Personal Data with third parties.
                    If we have to transfer your Personal Data to another country, such country
 must have an adequate data protection law. We will seek your consent where we need to
 send your Personal Data to a country without an adequate data protection law.
                    </p>
                   
                </div>

                <div className='mt-5'>
                    <h6>OUR USE OF OUR COOKIES</h6>
                    <p className='mt-3'>
                    What is a cookie? A cookie is a file created by websites you visit. Cookies are designed to make your online experience easier by saving browsing information. With cookies, sites can keep you signed in, remember your site preferences, and give you locally relevant content. We use cookies to enhance your interaction and convenience with our website and do not use cookies to record any personal information. Cookies only store information about a current or prior web session on our servers or information that you have asked to be stored.
                    </p>
                    <p>
                Stored statistical data is aggregated and used by us to improve our services and at no time do we personally identify you as the source of that data. You may refuse to accept cookies by activating the setting on your web browser which allows your web browser to refuse cookies. However, if you select this setting, you may be unable to access certain parts of our website and this may have a detrimental effect on your experience and the web based services that we can offer you. Unless you have adjusted your web browser settings to refuse cookies, our systems will automatically issue you with a cookie when you visit our website, and you are deemed to have consented to our use of this cookie.
                    </p>
                    <p>
                    We use our cookies for authentication. Our cookies track whether a user is logged in and under what name. They also streamline login information, so users don't have to remember site passwords every time they choose to log in. Our cookies are also designed to help us track multiple visits to our site over time. The information we gather from the cookies will enable us suggest other items or services that may be of interest to you, thereby helping us build a profile that best suits your activities on our site.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>SECURITY</h6>
                    <p className='mt-3'>
                    Ikinghub Exchange takes reasonable measures to help protect all Personal Data about you from loss, theft, misuse, unauthorized access, disclosure, alteration and destruction. Additionally, we implement policies designed to protect the confidentiality and security of your Personal Data, including this Data Protection Policy. We have also adopted mechanisms for the protection of your Personal Data through the set-up of firewalls, limited access to specified authorized individuals, encryption and continuous capacity building for relevant personnel. We therefore have digital and physical security measures to limit and eliminate possibilities of data privacy breach incidents
                    </p>
                    <p>
                    Whilst we continually strive to ensure that our systems and controls are updated to reflect technological changes, the transmission of information via the internet is not completely secure, and as such we cannot guarantee the security of your data transmitted to our online services which is at your own risk. We ask that you keep your information secure by ensuring that any username or password in relation to our online services is kept strictly confidential to you and not made available to any other person. You should stop using your username and password and notify us immediately if you suspect that someone else may be using your user details or password.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>HOW LONG WE KEEP YOUR PERSONAL DATA</h6>
                    <p className='mt-3'>
                    We will hold your Personal Data on Ikinghub Exchange’s systems for as long as it is necessary to fulfil the purpose for which it was collected or to comply with legal, regulatory or internal policy requirements.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>WHERE WE STORE YOUR PERSONAL DATA</h6>
                    <p className='mt-3'>
                        The Personal Data Ikinghub Exchange collects from you may be transferred to and stored at a destination outside Nigeria. By submitting your Personal Data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your Personal Data is treated securely with an appropriate level of protection and that the transfer is lawful.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>YOUR RIGHTS AS A DATA SUBJECT</h6>
                    <p className='mt-3'>
                    The law gives you certain rights in respect to your Personal Data Ikinghub Exchange hold about you. Below is a highlight of some of those rights. At any point while we are in possession of or processing your Personal Data, you, the Data Subject has the following rights:
                    </p>
                    <ul className='mt-3'>
                        <li className='mb-2 ml-3'>
                        RIGHT OF ACCESS – You have the right to request a copy of the information that we hold about you.
                        </li>
                        <li className='mb-2 ml-3'>
                        RIGHT TO RECTIFY – You have the right to correct and update the Personal Data we hold about you that is inaccurate or insufficient.
                        </li>
                        <li className='mb-2 ml-3'>
                        RIGHT TO BE FORGOTTEN – In certain circumstances you may ask for the data we hold about you to be erased from our record.
                        </li>
                        <li className='mb-2 ml-3'>
                        RIGHT TO RESTRICT PROCESSING – Where certain conditions apply, you have a right to restrict processing of your Personal Data.
                        </li>
                        <li className='mb-2 ml-3'>
                        RIGHT TO PORTABILITY – You have the right to have your Personal Data transferred to another organisation.
                        </li>
                        <li className='mb-2 ml-3'>
                        LODGE COMPLAINT – You have a right to lodge a complaint about the handling of your Personal Data with the National Information Technology Development Agency (NITDA) at 
                        <span><a href="mailto:info@nitda.gov.ngn"> info@nitda.gov.ngn.</a></span>
                        </li>
                        <li className='mb-2 ml-3'>
                        RIGHT TO OBJECT – You have the right to object to the Processing of Personal Data.
                        </li>
                    </ul>
                    <p>
                    If you would like to exercise any of the rights set out in this Section, please contact us using the details set out in the “questions and concerns” section below. We may refuse to provide access where we have legitimate reasons for doing so under applicable data privacy laws, and in exceptional circumstances, we may charge a fee for access if the relevant legislation allows us to do so, in which case we will provide reasons for our decision.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>BREACH/ PRIVACY VIOLATION</h6>
                    <p className='mt-3'>
                    In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data, Ikinghub Exchange shall within 72 (Seventy-Two) hours of having knowledge of such breach report the details of the breach to NITDA.
                    </p>
                    <p>

Furthermore, where we ascertain that such breach is detrimental to your rights and freedoms in relation to your Personal Data, we shall within 7 (Seven) days of having knowledge of the occurrence of such breach take steps to inform you of the breach incident, the risk to your rights and freedoms resulting from such breach and any course of action to remedy said breach.
                    </p>
                </div>

                <div className='mt-5'>
                    <h6>QUESTIONS OR CONCERNS</h6>
                    <p className='mt-3'>
                    If you have any questions or concerns about this Privacy Policy or would like to contact us for any reason, you can contact us at <span><a href="mailto:ikinghubxchange@gmail.com">ikinghubxchange@gmail.com</a></span>
                    </p>
                </div>

            </div>

        </div>

        <Footer/>
        </>
     );
}
 
export default PrivacyPage;