import React from 'react';
import styles from './landing.module.css'
import shield from '../../assets/icons/shield-tick.svg'
import cup from '../../assets/icons/cup.svg'
import convert_3d from '../../assets/icons/convert-3d-cube.svg'
import wallet from '../../assets/icons/wallet.svg'

const FeaturesPage = () => {
    return ( 
        <>
        <div className={styles.features_div} id="about">
            <div className='contain'>
                <div className='row'>
                    <div className='col-lg-5'>

                      <div className={styles.feature_first}>
                            <h3 className={styles.feature_title}>Wondering why we are the best?</h3>
                            <p className={`mt-3 ${styles.feature_subtitle}`}>
                            Because we are a user-oriented platform, the aim of our service is your comfort and NO ONE COMES CLOSE.</p>
                        </div>

                    </div>

                    <div className='col-lg-7'>
                        <div className='row'>
                            <div className='col-lg-6 mt-lg-5 mt-4'>

                                <div className={styles.feat_box}>

                                    <div className={styles.feat_icon}>
                                        <img src={shield} alt="shield" style={{width: 60, height: 60}} />
                                    </div>

                                    <div className='mt-4'>
                                        <h5 className={styles.feat_text}>Fully Secured System</h5>
                                    </div>

                                    <div className='mt-4'>
                                        <p className={styles.feat_subtext}>
                                        With a secure access system, your details are safe all through the transactions.</p>
                                    </div>

                                </div>

                                <div className={`mt-lg-4 mt-4 ${styles.feat_box}`}>

                                    <div className={styles.feat1_icon}>
                                        <img src={convert_3d} alt="convert" style={{width: 60, height: 60}} />
                                    </div>

                                    <div className='mt-4'>
                                        <h5 className={styles.feat_text}>Fully Automated System</h5>
                                    </div>

                                    <div className='mt-4'>
                                        <p className={styles.feat_subtext}>
                                        No one can boast of the efficiency of our transactions, our entire process is designed to ensure make you keep coming back.</p>
                                    </div>

                                </div>

                            </div>
                            <div className='col-lg-6'>

                                <div className={`mt-lg-0 mt-4 ${styles.feat_box}`}>

                                    <div className={styles.feat2_icon}>
                                            <img src={cup} alt="search" style={{width: 60, height: 60}} />
                                        </div>

                                        <div className='mt-4'>
                                            <h5 className={styles.feat_text}>Fast, Reliable and Easy-to-use</h5>
                                        </div>

                                        <div className='mt-4'>
                                            <p className={styles.feat_subtext}>
                                            Your time matters to us more, get your funds within seconds, we are that fast!</p>
                                        </div>

                                 </div>

                                 <div className={`mt-lg-4 mt-4 ${styles.feat_box}`}>

                                    <div className={styles.feat3_icon}>
                                        <img src={wallet} alt="wallet" style={{width: 60, height: 60}} />
                                    </div>

                                    <div className='mt-4'>
                                        <h5 className={styles.feat_text}>Simply Amazing Rates</h5>
                                    </div>

                                    <div className='mt-4'>
                                        <p className={styles.feat_subtext}>
                                        When you think of juicy rates, you would think Ikinghub. It’s your money, You rule!</p>
                                    </div>

                                 </div>


                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
        </>
     );
}
 
export default FeaturesPage;