const initState = {
    transactions: [],
    transactionSearch: [],
    loader: false,
    withdrawsuccess: false
  };
  
  const transactionReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Transaction':
          return {
                ...state,
                transactions: action.data,
                transactionSearch: action.data,
                loader: false
            }
    case "searchTransactions":
        let res = state.transactionSearch.filter((val) => {
            return (
            val.status.toLowerCase().includes(action.data.toLowerCase()) ||
            val.amount.toString().includes(action.data.toLowerCase()) 
            );
        });
    return {
        ...state,
        transactions: res
        }
        case 'Loader':
            return{
                ...state,
                loader: true
            }
        case 'Transaction_Error':
            return{
                ...state,
                loader: false
            }
        case 'WithdrawSuccess':
            return{
                ...state,
                withdrawsuccess: true,
            }
        case "Withdraw_Error":
            return {
                ...state,
                withdrawsuccess: false,
            };
            case "clearWithdrawal":
            return {
                ...state,
                withdrawsuccess: false,
            };
      default:
        return state;
    }
  };
  
  export default transactionReducer;
  