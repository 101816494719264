import React, {useState, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './dashboard.module.css'
import icon1 from '../../../assets/icons/icon-1.svg'
import icon2 from '../../../assets/icons/icon-2.svg'
import rocket from '../../../assets/icons/rocket-white.svg'
import DataTable from "react-data-table-component";
import DoughnutChart from '../../../components/Charts/Doughnut';
import {Link, useNavigate} from 'react-router-dom'
import Modal from "../../../components/Modals/PinModal";
import PinInput from "react-pin-input";
import { connect } from 'react-redux';
import { SetPin } from '../../../store/actions/auth';
import { getTransactions } from '../../../store/actions/transactions';
import { getDashboardAnalytics, getWalletBalance } from '../../../store/actions/dashboard';
import Moment from "react-moment";
import cogoToast from 'cogo-toast'

const UsersDashboard = ({pinload, createPin, isSetPin, isAuthenticated, fetchTransactions, transactions, 
  loader, fetchBalance, balance, pendingCount, fetchAnalytics}) => {

    const [showPinModal, setPinModal] = useState(false);
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [valid, setValid] = useState(false);


    const navigate = useNavigate()

    useEffect(()=>{
      let status = 'Pending'
      fetchTransactions(status)
      fetchBalance()
      fetchAnalytics()
    },[fetchTransactions, fetchBalance, fetchAnalytics])

    const handlePin = (val) => {
        setPin(val);
      };
    
      const handleConfirmPin = (val) => {
        setConfirmPin(val);
      };

      const handleSubmit = () => {
        // check if pin and confirm pin are correct
        if(pin === ''){
          cogoToast.info('Please enter a pin for your transactions!')
        }
        else{
          if (pin !== confirmPin) {
            setValid(true);
          } else {
            setValid(false);
            let val = {
              pin,
            };
            console.log(val)
            createPin(val);
          }
        }
        
      };

     useEffect(()=>{
        if(!isSetPin){
            setPinModal(true)
        }
      },[isSetPin])

   

    useEffect(() => {
     if (isAuthenticated) {
        if (isSetPin) {
            navigate('/dashboard')
            setPinModal(false)
        } else {
            setPinModal(true)
        }
     }
    }, [isAuthenticated, navigate, isSetPin]);
    

    const columns = [
        {
          name: "Type",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.type}`}</span>
          ),
        },
        {
          name: "Amount",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`NGN ${row.amount}`}</span>
          ),
        },
        {
          name: "Date",
          cell: (row) => (
            <span className={styles.recent_table_row}>
               <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
            </span>
          ),
        },
        {
          name: "Status",
          cell: (row) => (
            <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
          ),
        },
        {
          name: "Action",
          button: true,
          cell: (row) => (
            <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
          ),
        },
      ];

      const ViewRequest = (id) =>{
        navigate(`/my-wallet/details/${id}`)
      }


      const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Successful":
            result = "completed_icon";
            break;
          case "Failed":
            result = "failed_icon";
            break;
          default:
            result = "failed_icon";
            break;
        }
        return result;
      };



    const numberWithCommas = (x) => { 
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
      }
    
    

      
    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Dashboard</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">


            <Modal
              title=""
              show={showPinModal}
              onClose={() => setPinModal(false)}
            >
                <div className={`text-center mt-3 ${styles.title_modal}`}>
                Please setup your pin to complete 
                    your account details
                </div>

                <div className={styles.pin_container}>
                     <div className='mt-4'>
                        <p className={styles.pin_title}>
                        Pin Setup
                        </p>
                    </div>

                     {/* pin layout */}
                    <div className='mt-4'>
                        <p className={styles.pin_heading}>Set Pin</p>
                        <PinInput
                        length={4}
                        initialValue={pin}
                        focus
                        secret
                        type="numeric"
                        onChange={handlePin}
                        inputStyle={{ borderColor: "rgba(26, 66, 149, 0.25)" }}
                        inputFocusStyle={{ borderColor: "#1A4295" }}
                        />
                    </div>

                     <div className="mt-3">
                            <p className={styles.pin_heading} >Confirm Pin</p>
                            <PinInput
                            length={4}
                            initialValue={confirmPin}
                            secret
                            type="numeric"
                            onChange={handleConfirmPin}
                            inputStyle={
                                valid
                                ? { borderColor: "rgb(220, 53, 69)" }
                                : { borderColor: "rgba(26, 66, 149, 0.25)" }
                            }
                            inputFocusStyle={{ borderColor: "#1A4295" }}
                            />
                            <small style={{ color: "rgb(220, 53, 69)" }}>
                            {valid ? "Pin must match!" : ""}
                            </small>
                    </div>

                    <div className="mt-4">
                        <button
                        type="submit"
                        className={`btn ${styles.confirm_pinbtn}`}
                        style={{ fontWeight: "normal" }}
                        disabled={pinload}
                        onClick={handleSubmit}
                        >
                        Confirm
                        </button>
                     </div>

                </div>

              <div className="mt-4">
                <p className={styles.pin_footer_text}>
                  Your pin will be used to complete all withdrawal request,
                  Please keep it safe.
                </p>
              </div>
            

            </Modal>

            <div className='row mt-5'>
                <div className='col-lg-8'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className={styles.wallet_card_div}>
                                <div className={styles.wallet_value_div}>
                                    <div>
                                        <img src={icon1} alt="icon"/>
                                    </div>
                                    <div className='ml-3 ml-md-3'>
                                        <h6 className={styles.wallet_title}>Wallet Balance</h6>
                                        <h5 className={styles.wallet_subtitle}> NGN {numberWithCommas(balance)}</h5>
                                        <div className={`mt-3 mt-md-2 ${styles.widthraw_div}`}>
                                        <Link to="/my-wallet/withdraw">Withdraw Funds</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 mt-md-0 mt-4'>
                             <div className={styles.pending_card_div} >
                                <div className={styles.pending_value_div}>
                                    <div>
                                        <img src={icon2} alt="icon"/>
                                    </div>
                                    <div className='ml-3 ml-md-3'>
                                        <h6 className={styles.pending_title}>Pending Transactions</h6>
                                        <h5 className={styles.pending_subtitle}>{pendingCount}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* tables - pending transactions */}
                    <div className='mt-4 mt-md-5'>
                        <div className={styles.dash_transact_container}>
                            <DataTable
                                title="Pending Transactions"
                                pagination
                                persistTableHead
                                columns={columns}
                                data={transactions}
                                progressPending={loader}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 mt-md-0 mt-4'>
                    <div className={styles.trade_card_div}>
                        <div className={styles.trade_card_title_div}>
                            <div>
                                <img src={rocket} alt="rocket" className='img-fluid' />
                            </div>
                            <div className='ml-3' style={{flex: 1}}>
                                <h6 className={styles.trade_title}>Trade with us</h6>
                                <p className={styles.trade_subtitle}>Trade your gift cards and crypto currencies with ease at mouth watering rates today.</p>
                            </div>
                        </div>

                        <div className='mt-2'>
                            <Link to="/start-trade" className={`btn ${styles.trade_btn}`}>Trade Now</Link>
                        </div>
                    </div>

                    <div className={`mt-4 ${styles.analytics_div}`}>
                        <h6 className={styles.analytics_title}>Analytics</h6>
                        <DoughnutChart />
                    </div>
                </div>
            </div>  


            </div>
        </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
      pinload: state.auth.pinload,
      isSetPin: state.auth.isSetPin,
      isAuthenticated: state.auth.isAuthenticated,
      transactions: state.transaction.transactions,
      loader: state.transaction.loader,
      balance: state.dashboard.WalletBalance,
      pendingCount: state.dashboard.pendingTransactions
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      createPin: (values) => dispatch(SetPin(values)),
      fetchBalance: () => dispatch(getWalletBalance()),
      fetchTransactions: (status) => dispatch(getTransactions(status)),
      fetchAnalytics: () => dispatch(getDashboardAnalytics()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UsersDashboard);