import React, {useState, useEffect, useCallback} from 'react';
import './sidebar.css'  
import Logo from '../../assets/icons/iking-black.png'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import home_icon from '../../assets/icons/home_icon.svg'
import home_icon_active from '../../assets/icons/home_icon_active.svg'
import trade_icon from '../../assets/icons/trade_icon.svg'
import trade_icon_active from '../../assets/icons/trade_icon_active.svg'
import wallet_icon from '../../assets/icons/wallet_icon.svg'
import wallet_icon_active from '../../assets/icons/wallet_icon_active.svg'
import notifications_icon from '../../assets/icons/notifications_icon.svg'
import notifications_icon_active from '../../assets/icons/notifications_icon_active.svg'
import account_icon from '../../assets/icons/account_icon.svg'
import account_icon_active from '../../assets/icons/account_icon_active.svg'
import setting_icon from '../../assets/icons/Settings_icon.svg'
import setting_icon_active from '../../assets/icons/Settings_icon_active.svg'
import profile_icon from '../../assets/icons/profile_icon.svg'
import profile_icon_active from '../../assets/icons/profile_icon_active.svg'
import bell_icon from '../../assets/icons/bell_icon.svg'
import user_icon from '../../assets/icons/user_icon.svg'
import logout_icon from '../../assets/icons/Logout.svg'
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { logOut } from '../../store/actions/auth';
import PopComp from '../Modals/popups';
import logout_colored from "../../assets/icons/logout_colored.svg"

const UserSidebar = (props) => {
    const [sideShow, setSideShow] = useState(false);

    const [show, setShow] = useState(false)

    const showModal = () =>{
      setShow(!show)
    }
  
    const handleClose = () =>{
      setShow(false)
    }

    const handleLogout = () =>{
      Logout();
      document.body.classList.remove("modal-open");
      navigate('/login')
    }

    const { Logout, profile } = props;

    const navigate  = useNavigate()
  
  // check for which path you are on
  const isActive = useLocation().pathname;

  useEffect(() => {
    document.body.classList.add("userBg");
  }, []);

    const ToggleSidebar = () => {
        setSideShow(sideShow ? false : true);
    };

    const ToggleLogout = () => {
      setShow(true)
    };
  
     const closeSideBar = useCallback(() => {
       setSideShow(sideShow ? false : true);
     }, [sideShow]);
    
    useEffect(() => {
    if (sideShow) {
        document.body.addEventListener("click", closeSideBar);

        return () => document.body.removeEventListener("click", closeSideBar);
    }
    }, [closeSideBar, sideShow]);
    
    return ( 
        <>
          <PopComp show={show} 
          showModal={showModal}
          onClose={() => setShow(false)}
          >
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className='logout_icon_div'>
                    <img src={logout_colored} alt="save"/>
                </div>
              </div>

            <div className='text-center'>
                <h6 className='pop_title'>Logout</h6>
                <p className='pop_subtitle'>Are you sure you want to log out of your account?</p>
            </div>

            <div className='logout-cta'>
              <button className='btn cancel-btn'  onClick={() => handleClose()}>Cancel</button>
              <button className='btn btn-action' onClick={handleLogout}>Log Out</button>
            </div>
        </PopComp>
    <div className={sideShow ? "userside show" : "userside"}>
        {/* logo */}
        <div className="logo mt-4">
          <img src={Logo} alt="logo" className={`img-fluid`} />
        </div>
        {/* nav list */}
        <div className="v-list">
          <div>
            {/* first */}
            <Link
              to="/dashboard"
              className={
                isActive.includes("/dashboard")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/dashboard")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/dashboard") ? (
                  <img src={home_icon_active} alt="dashboard" />
                ) : (
                  <img src={home_icon} alt="dashboard" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/dashboard") ? "active" : ""}
                >
                  Dashboard
                </span>
              </div>
            </Link>

            <Link
              to="/start-trade"
              className={
                isActive.includes("/start-trade")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/start-trade")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/start-trade") ? (
                  <img src={trade_icon_active} alt="start-trade" />
                ) : (
                  <img src={trade_icon} alt="start-trade" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/start-trade") ? "active" : ""}
                >
                  Start Trade
                </span>
              </div>
            </Link>

            <Link
              to="/trades"
              className={
                isActive.includes("/trade")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/trade")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/trade") ? (
                  <img src={trade_icon_active} alt="trades" />
                ) : (
                  <img src={trade_icon} alt="trades" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/trade") ? "active" : ""}
                >
                  Trades
                </span>
              </div>
            </Link>

            <Link
              to="/my-wallet"
              className={
                isActive.includes("/my-wallet")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/my-wallet")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/my-wallet") ? (
                  <img src={wallet_icon_active} alt="wallet" />
                ) : (
                  <img src={wallet_icon} alt="wallet" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/my-wallet") ? "active" : ""}
                >
                  My Wallet
                </span>
              </div>
            </Link>

            <Link
              to="/notifications"
              className={
                isActive.includes("/notifications")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/notifications")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/notifications") ? (
                  <img src={notifications_icon_active} alt="notifications icon" />
                ) : (
                  <img src={notifications_icon} alt="notifications icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/notifications") ? "active" : ""}
                >
                  Notifications 
                  {/* <span className='notification-count'>6</span> */}
                </span>
              </div>
            </Link>

            <Link
              to="/account-details"
              className={
                isActive.includes("/account")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/account")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/account") ? (
                  <img src={account_icon_active} alt="notifications icon" />
                ) : (
                  <img src={account_icon} alt="account icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/account") ? "active" : ""}
                >
                  Accounts
                </span>
              </div>
            </Link>

            <Link
              to="/security/manage-pin"
              className={
                isActive.includes("/security")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/security")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/security") ? (
                  <img src={setting_icon_active} alt="security icon" />
                ) : (
                  <img src={setting_icon} alt="security icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/security") ? "active" : ""}
                >
                  Security
                </span>
              </div>
            </Link>

            <Link
              to="/profile"
              className={
                isActive.includes("/profile")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/profile")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/profile") ? (
                  <img src={profile_icon_active} alt="profile icon" />
                ) : (
                  <img src={profile_icon} alt="profile icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/profile") ? "active" : ""}
                >
                  Profile
                </span>
              </div>
            </Link>
          </div>


          {/* sidebar footer */}
          <div className='sidebar-footer-div'>
              <div className='sidebar-user-icon'>
                <img src={user_icon} alt="user" />
              </div>
               <div className="sidebarnamedisplay">
                <p className='mb-0 '>Hi, {profile && profile.hasOwnProperty("firstName") ? profile.firstName: "Nill"}</p>
              </div>
              <div className='sidebar-logout'
                 onClick={ToggleLogout}
              >
                <span data-tip data-for="logoutTip"><img src={logout_icon} alt="logout" /></span>
              </div>

              <ReactTooltip id="logoutTip" place="left" effect="solid">
                Logout
              </ReactTooltip>
          </div>

         
        </div>
    </div>

      {/* navbar */}
      <nav>
        <div className="sidehead">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* icon for hamburger */}
            <div
              onClick={ToggleSidebar}
              className="d-md-none d-sm-block"
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              <i className="mdi mdi-menu" style={{ fontSize: 24, color:"#F7931A" }}></i>
            </div>

            {/* back arrow */}
            <div>
              {props.children}  
            </div>  

           
            <div style={{ flexGrow: 1 }}></div>
              <div>
                <div>
                  <div>
                    <img
                      className="imageStyle"
                      src={bell_icon}
                      alt="user"
                    />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </nav>

        </>
     );
}


const mapStateToProps = (state) =>{
  return{
      profile: state.auth.profile,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
      Logout: () => dispatch(logOut()),
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);