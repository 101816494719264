import React from 'react'
import styles from './landing.module.css'
import heroImg from '../../assets/images/heroImg.png'
import { Link } from 'react-router-dom';
import dots from '../../assets/images/Dot.png'

const Hero = () => {
    return ( 
        <>
         <div className={styles.hero_div}>
             <div className='contain'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className={`${styles.hero_caption_div}`}>
                            <h2 className={styles.hero_title}>Welcome to IkingHub Your Digital Companion!</h2>
                            <p className={styles.hero_subtitle}>Even when the day bleeds, we offer you only the best!</p>

                            <div className={`${styles.cta_hero_btn}`}>

                                <div className='mr-4 mr-md-0'>
                                    <Link to="/login" className={`btn ${styles.btn_signIn}`}>
                                        Sign In
                                    </Link>
                                </div>
                                <div>

                                <Link to="/register"  className={`btn ${styles.btn_register}`}>
                                        Create Account
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
             </div>

                <div className={styles.hero_dots}>
                    <img src={dots} alt="dots" className='img-fluid' />
                </div>

                <div className={styles.hero_img}>
                        <img src={heroImg} className="img-fluid" alt="hero" />
                </div>

            </div>


        </>
     );
}
 
export default Hero;