const initState = {
    trades: [],
    tradesSearch: [],
    loader: false,
    btnLoader: false,
    trade_success: false,
    trade_result: {},
    trade_display: {},
    cryptosuccess: false,
    imageAdded: {},
    imageLoader: false,
    updateLoad: false,
    proofUpdated: false
  };
  
  const tradeReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Trades':
          return {
                ...state,
                trades: action.data,
                tradesSearch: action.data,
                loader: false
            }
        case "searchTrade":
            let res = state.tradesSearch.filter((val) => {
                return (
                val.tradeStatus.toLowerCase().includes(action.data.toLowerCase())
                );
            });
        return {
            ...state,
            trades: res
         }
        case 'Loader':
            return{
                ...state,
                loader: true
            }
        case 'Trades_Error':
            return{
                ...state,
                loader: false
            }
        case 'TRADE_START':
            return{
                ...state,
                btnLoader: true
            }
        case 'TRADE_SUCCESS':
            return{
                ...state,
                btnLoader: false,
                trade_success: true
            }
        case 'TRADE_ERROR':
            return{
                ...state,
                btnLoader: false,
            }
        case 'clearCardTradeSuccess':
            return{
                ...state,
                trade_success: false
            }
        case "CryptoTradeSuccess":
            return {
                ...state,
                trade_result: action.data,
                cryptosuccess: true
            };
        case "CryptoTradeDisplay":
            return {
                ...state,
                trade_display: action.data
            }
        case "CryptoTrade_Error":
            return {
                ...state,
            }
         
        case "clearCryptoTradeSuccess":
            return{
                ...state,
                cryptosuccess: false
            }
        case "clearCryptoResult":
            return{
              ...state,
              trade_display: {},
              trade_result: {},  
        }
        case 'STARTUPLOAD':
            return{
                ...state,
                imageLoader: true
            }
        case 'STOPUPLOAD':
            return{
                ...state,
                imageLoader: false
            }
        case "ImageSuccess":
            return{
                ...state,
                imageAdded: action.data
            }
        case 'STARTUPDATE':
            return{
                ...state,
                updateLoad: true
            }
        case 'ProofUpdated':
            return{
                ...state,
                proofUpdated: true
            }
        case 'clearProof':
            return{
                ...state,
                proofUpdated: false,
                imageAdded: {}
            }
        case 'STOPUPDATE':
            return{
                ...state,
                updateLoad: false
            }
      default:
        return state;
    }
  };
  
  export default tradeReducer;
  