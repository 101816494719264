import React,{useState} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './starttrade.module.css'
import { useNavigate } from 'react-router-dom';

const StartTradePage = () => {

  const [initialTab, setTab] = useState(1);

  const navigate = useNavigate()

  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "Giftcard" },
    { id: 2, name: "tab-2", text: "Crypto" },
  ]);

  // tab layout
  const tabLayout = tabData.map((item) => (
    <li key={item.id}
    onClick={() => handleToggle(item.id)}
    >
        <p 
           className={initialTab === item.id ? `${styles.tab} ${styles.tab_active}` : `${styles.tab}`}
        >{item.text}</p>
    </li>
  ));

  const handleToggle = (id) =>{
    setTab(id)
  }

  const handleNext = () =>{
    if(initialTab === 1){
        navigate('/start-trade/giftcard')
    }
    else{
        navigate('/start-trade/crypto')
    }
  }

    return ( 
        <>
         <UserSidebar>
            <div>
                <p className="titlehead">Start Trade</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className={styles.card_lay}>
                    <div className={styles.card_div}>

                        <h6 className={styles.card_title}>Choose what to trade!</h6>

                        <div className={styles.tab_div}>
                            <ul className={styles.tab_list}>
                                {tabLayout}
                            </ul>
                        </div>

                        <div className='mt-4'>
                             <button
                                 type="submit"
                                 onClick={handleNext}
                                className={`btn ${styles.btn_sell}`}
                            >
                               Next
                            </button>

                        </div>

                    </div>

                </div>


            </div>
        </div>
        </>
     );
}
 
export default StartTradePage;